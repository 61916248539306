export const setItem = (name, value) => {
  if (typeof value !== 'string') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(name, value)
}

export const removeItem = (name) => {
  localStorage.removeItem(name)
}

export const getItem = (name) => {
  let data = localStorage.getItem(name)
  try {
    data = JSON.parse(data)
  } catch (e) {
    return ''
  }
  return data
}

export const checkItem = (name) => {
  const user = this.getItem(name)
  if (user !== '' && user !== null) {
    return true
  }
  return false
}

// Hàm để tải state từ local storage
export const loadState = () => {
  try {
    const serializedState = getItem(
      process.env.REACT_APP_PRESIT_STORE || 'presitStore'
    )
    if (serializedState === null) {
      return undefined // Trả về undefined để sử dụng giá trị khởi tạo từ reducer
    }
    return serializedState
  } catch (err) {
    console.error('Could not load state', err)
    return undefined
  }
}

export const saveState = (state) => {
  const { cache } = state
  const persistedState = loadState()
  try {
    const serializedState = JSON.stringify({
      ...cache,
      category: {
        ...persistedState?.category || {},
      },
    })
    localStorage.setItem(
      process.env.REACT_APP_PRESIT_STORE || 'presitStore',
      serializedState
    )
  } catch (err) {
    console.error('Could not save state', err)
  }
}
