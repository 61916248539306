import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, useTheme } from '@mui/material'
import FilledButton from 'components/Button/FilledButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAutoLockSettingRequest,
  saveAutoLockSettingRequest,
} from './store/actions'
import { getAutoLockSettingSelector } from './store/selectors'
import Switch from 'components/Switch/IOSSwitch'
import TextInput from 'components/Input/TextInput'

const MAX_VALUE = 2147483647

const AutoLock = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { data, isLoading } = useSelector(getAutoLockSettingSelector())

  const [autoLock, setAutoLock] = useState({})
  const [checkingStatus, setCheckingStatus] = useState({
    lockUserIfReportReachedLimitation: true,
    lockContentIfReportReachedLimitation: true,
  })

  const handleResponseAutoLock = (data) => {
    setAutoLock(data)
    setCheckingStatus(() => ({
      lockUserIfReportReachedLimitation:
        data.value.lockUserIfReportReachedLimitation !== MAX_VALUE,
      lockContentIfReportReachedLimitation:
        data.value.lockContentIfReportReachedLimitation !== MAX_VALUE,
    }))
  }

  const handleSwitch = (id, checked) => {
    setCheckingStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }))
  }

  const handleChangeValue = (id, value) => {
    const newAutoLock = {
      ...autoLock,
      value: {
        ...autoLock.value,
        [id]: parseInt(value),
      },
    }
    setAutoLock(newAutoLock)
  }

  const hasChangeEnableUserLimit =
    (data?.value?.lockUserIfReportReachedLimitation !== MAX_VALUE) !==
    checkingStatus?.lockUserIfReportReachedLimitation

  const hasChangeEnableContentLimit =
    (data?.value?.lockContentIfReportReachedLimitation !== MAX_VALUE) !==
    checkingStatus?.lockContentIfReportReachedLimitation

  const isEnableSaveChange =
    (JSON.stringify(data) !== JSON.stringify(autoLock) &&
      !!autoLock?.value?.lockUserIfReportReachedLimitation &&
      !!autoLock?.value?.lockContentIfReportReachedLimitation) ||
    hasChangeEnableUserLimit ||
    hasChangeEnableContentLimit

  const fetchAutoLockSetting = useCallback(() => {
    dispatch(getAutoLockSettingRequest(handleResponseAutoLock))
  }, [dispatch])

  const handleSaveChange = () => {
    dispatch(
      saveAutoLockSettingRequest(
        {
          ...autoLock.value,
          lockUserIfReportReachedLimitation:
            checkingStatus.lockUserIfReportReachedLimitation
              ? autoLock.value.lockUserIfReportReachedLimitation
              : MAX_VALUE,
          lockContentIfReportReachedLimitation:
            checkingStatus.lockContentIfReportReachedLimitation
              ? autoLock.value.lockContentIfReportReachedLimitation
              : MAX_VALUE,
        },
        fetchAutoLockSetting
      )
    )
  }

  useEffect(() => {
    fetchAutoLockSetting()
  }, [fetchAutoLockSetting])

  return (
    <Box sx={{ width: '100%', height: 'calc(100vh - 147px)' }}>
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          borderRadius: '8px',
          p: 3,
          height: '100%',
        }}
      >
        <Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ typography: 'title' }}>Auto lock</Box>
              <FilledButton
                isEnable={isEnableSaveChange}
                disabled={!isEnableSaveChange}
                label={'Save changes'}
                onClick={handleSaveChange}
              />
            </Box>
            <Box sx={{ typography: 'noteGrey' }}>
              Automatically locked the users, contents that reached the limit
              reports and no longer displayed in the app until admin unlock.
            </Box>
          </Box>
        </Box>
        {isLoading ? (
          <Box
            sx={{
              display: isLoading ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                p: 3,
                borderBottom: '1px solid #2E3A59',
                display: 'flex',
                alignItems: 'start',
              }}
            >
              <Switch
                onChange={handleSwitch}
                checked={checkingStatus.lockUserIfReportReachedLimitation}
                id="lockUserIfReportReachedLimitation"
              />
              <Box>
                <Box>User reports limit</Box>
                <Box sx={{ typography: 'noteGrey' }}>
                  The amount of new reports the user got at the same time.{' '}
                </Box>

                <TextInput
                  variant="outlined"
                  sx={{ mt: 2 }}
                  disabled={!checkingStatus.lockUserIfReportReachedLimitation}
                  value={
                    autoLock?.value?.lockUserIfReportReachedLimitation || ''
                  }
                  onChange={(e) =>
                    handleChangeValue(
                      'lockUserIfReportReachedLimitation',
                      e.target.value.replace(/[^0-9]/g, '')
                    )
                  }
                  inputProps={{
                    pattern: '[0-9]*',
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                p: 3,
                borderBottom: '1px solid #2E3A59',
                display: 'flex',
                alignItems: 'start',
              }}
            >
              <Switch
                onChange={handleSwitch}
                checked={checkingStatus.lockContentIfReportReachedLimitation}
                id="lockContentIfReportReachedLimitation"
              />
              <Box>
                <Box>Content reports limit</Box>
                <Box sx={{ typography: 'noteGrey' }}>
                  The amount of new reports the content (stories, collections)
                  got at the same time.{' '}
                </Box>
                <TextInput
                  variant="outlined"
                  sx={{ mt: 2 }}
                  value={autoLock?.value?.lockContentIfReportReachedLimitation}
                  disabled={
                    !checkingStatus.lockContentIfReportReachedLimitation
                  }
                  onChange={(e) =>
                    handleChangeValue(
                      'lockContentIfReportReachedLimitation',
                      e.target.value.replace(/[^0-9]/g, '')
                    )
                  }
                  inputProps={{
                    pattern: '[0-9]*',
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AutoLock
