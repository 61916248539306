import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, Link } from '@mui/material'
import Tabs from 'components/Tabs/Tabs'
import {
  LINK_MAPPING,
  SUBPAGE_TABS,
  TITLE_MAPPING,
} from 'constants/settings/consts'
import FilledButton from 'components/Button/FilledButton'
import TextEditor from 'components/TextEditor'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSubpageSettingRequest,
  saveSubpageSettingRequest,
} from './store/actions'
import { getSubpageSettingSelector } from './store/selectors'
import { useNavigate, useParams } from 'react-router-dom'

const SubpagesSetting = () => {
  const { tab } = useParams()
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState(tab)

  const [currentData, setCurrentData] = useState('')
  const [content, setContent] = useState('')
  const dispatch = useDispatch()
  const { isLoading } = useSelector(getSubpageSettingSelector())

  const handleChangeTab = (newValueTab) => {
    setContent(currentData[newValueTab])
    navigate(`/settings/subpages/${newValueTab}`)
    setSelectedTab(() => newValueTab)
  }

  const handleChangeValue = (event, editor) => {
    const dataEditting = editor.getData() // Get HTML from editor
    setContent(dataEditting)
  }

  const handleResponseSubpage = useCallback(
    (data) => {
      setContent(() => data[selectedTab])
      setCurrentData(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const fetchSubpageSetting = useCallback(() => {
    dispatch(getSubpageSettingRequest(handleResponseSubpage))
  }, [dispatch, handleResponseSubpage])

  const handleSaveChange = () => {
    dispatch(
      saveSubpageSettingRequest(content, selectedTab, fetchSubpageSetting)
    )
  }

  useEffect(() => {
    fetchSubpageSetting()
  }, [fetchSubpageSetting])

  return (
    <Grid sx={{ p: 3, display: 'flex', flex: 1 }}>
      <Box sx={{ width: '237px' }}>
        <Tabs
          tabs={SUBPAGE_TABS}
          onChangTab={handleChangeTab}
          selectedTab={selectedTab}
          orientation="vertical"
          sx={{
            backgroundColor: '#111422',
            '&.MuiTabs-root': {
              height: 'calc(100vh - 100px)',
            },
            '& .MuiTab-root': {
              textAlign: 'start',
              alignItems: 'start',
            },
            '& .Mui-selected': {
              backgroundColor: '#8EF35114',
            },
            '& .MuiTabs-indicator': {
              left: 0,
            },
          }}
        />
      </Box>
      <Box sx={{ flex: 1, backgroundColor: '#111422', p: 3 }}>
        {isLoading ? (
          <Box
            sx={{
              display: isLoading ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ typography: 'title' }}>
                  {TITLE_MAPPING[selectedTab]}
                </Box>
                <FilledButton
                  isEnable={true}
                  label={'Save changes'}
                  onClick={handleSaveChange}
                />
              </Box>
              <Box sx={{ typography: 'noteGrey' }}>
                Page link:{' '}
                <Link
                  href={`https://tupme.com/${LINK_MAPPING[selectedTab]}`}
                  underline="none"
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://tupme.com/{LINK_MAPPING[selectedTab]}
                </Link>
                {}
              </Box>
            </Box>
            <Box sx={{ mt: 4 }}>
              <TextEditor onChange={handleChangeValue} data={content || ''} />
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default SubpagesSetting
