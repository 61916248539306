import { produce } from 'immer'

import { types } from './constants'

export const initialState = {
  list: {
    records: false,
    isLoading: true,
    total: 0,
  },
  // // store for table detail content
  // table: {
  //   sent: {
  //     total: 0,
  //     records: [],
  //   },
  //   schedual: {
  //     total: 0,
  //     records: [],
  //   },
  //   isLoading: true,
  // },
  detail: {
    data: {},
    isLoading: true,
  },
  isLoading: true,
}

const notifyReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_LIST_NOTIFY_REQUEST:
        draft.list.isLoading = true
        break
      case types.GET_LIST_NOTIFY_SUCCESS:
        const { data } = payload
        draft.isLoading = false
        draft.list.isLoading = false
        draft.list.records = data.records
        draft.list.total = data.total
        break
      case types.GET_LIST_NOTIFY_FAILED:
        draft.list.isLoading = false
        draft.isLoading = false
        break
      case types.GET_DETAIL_NOTIFY_REQUEST:
        draft.detail.isLoading = true
        break
      case types.GET_DETAIL_NOTIFY_SUCCESS:
        draft.detail.isLoading = false
        draft.detail.data = payload
        break
      case types.GET_DETAIL_NOTIFY_FAILED:
        draft.detail.isLoading = false
        break
      default:
        break
    }
  })

export default notifyReducer
