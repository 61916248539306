import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'

export const getStatisticReport = (startDate, endDate) => {
  return axiosClient.get(
    buildUrlWithQueryStrings('/statistics/report', {
      from: startDate,
      to: endDate,
    })
  )
}
export const getStatisticAutoLock = (startDate, endDate) => {
  return axiosClient.get(
    buildUrlWithQueryStrings('/statistics/auto-locked', {
      from: startDate,
      to: endDate,
    })
  )
}

export const getMetricsOverview = (startDate, endDate) => {
  return axiosClient.get(
    buildUrlWithQueryStrings('/statistics/overview', {
      from: startDate,
      to: endDate,
    })
  )
}

export const getUserActive = (startDate, endDate) => {
  return axiosClient.get(
    buildUrlWithQueryStrings('/statistics/user-active', {
      from: startDate,
      to: endDate,
    })
  )
}

export const getUserAverage = (startDate, endDate) => {
  return axiosClient.get(
    buildUrlWithQueryStrings('/statistics/country', {
      from: startDate,
      to: endDate,
    })
  )
}
