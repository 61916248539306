import { createTheme } from '@mui/material/styles'

const colorPalette = {
  main: {
    500: '#111422',
    600: '#8EF351',
  },
  primary: {
    900: '#0F2413',
    800: '#1E4627',
    700: '#2E653A',
    600: '#4C905B',
    500: '#65B076',
    400: '#8ACF9A',
    300: '#B5E9C1',
    200: '#CDF0D5',
    100: '#E7F9EB',
  },
  basic: {
    900: '#101426',
    800: '#222B45',
    700: '#2E3A59',
    600: '#8F9BB3',
    500: '#C5CEE0',
    400: '#B8BBC4',
    300: '#D1D4DD',
    200: '#DDE0EA',
    100: '#FFFFFF',
  },
  success: {
    900: '#034B0F',
    800: '#077018',
    700: '#0C9521',
    600: '#0EB627',
    500: '#1BD737',
    400: '#53F16A',
    300: '#8AFF9B',
    200: '#BEFFC8',
    100: '#E3FFE7',
  },
  info: {
    900: '#002885',
    800: '#0041A8',
    700: '#0057C2',
    600: '#006FD6',
    500: '#0095FF',
    400: '#42AAFF',
    300: '#94CBFF',
    200: '#C7E2FF',
    100: '#F2F8FF',
  },
  warning: {
    900: '#412300',
    800: '#763F00',
    700: '#A55900',
    600: '#DC7600',
    500: '#FF8900',
    400: '#FF9D2B',
    300: '#FFB45E',
    200: '#FFD5A4',
    100: '#FFF1E0',
  },
  error: {
    900: '#5C0005',
    800: '#6E0006',
    700: '#A60009',
    600: '#CF1720',
    500: '#F5222D',
    400: '#FF3943',
    300: '#FF6068',
    200: '#FF9EA3',
    100: '#FFE1E2',
  },
  pink: {
    900: '#FCE4EC',
    800: '#F8BBD0',
    700: '#F48FB1',
    600: '#F06292',
    500: '#EC407A',
    400: '#E91E63',
    300: '#D81B60',
    200: '#FFD6D9',
    100: '#FFF2F2',
  },
  yellow: {
    900: '#6A5600',
    800: '#927600',
    700: '#BE9A00',
    600: '#E4B900',
    500: '#FFCF00',
    400: '#FFD41B',
    300: '#FFDB3E',
    200: '#FFE886',
    100: '#FFF4C6',
  },
  purple: {
    900: '#26117A',
    800: '#371B94',
    700: '#4F28B8',
    600: '#6A39DB',
    500: '#884DFF',
    400: '#A375FF',
    300: '#C5A8FF',
    200: '#E0D1FF',
    100: '#F7F2FF',
  },
}

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: {
      main: colorPalette.primary[500],
      secondary: colorPalette.main[600],
    },
    secondary: {
      main: colorPalette.main[600],
    },
    basic: {
      ...colorPalette.basic,
    },
    success: {
      main: colorPalette.success[500],
    },
    info: {
      main: colorPalette.info[500],
      light: colorPalette.info[600],
      medium: colorPalette.info[700],
      dark: colorPalette.info[800],
      ...colorPalette.info,
    },
    warning: {
      main: colorPalette.warning[500],
    },
    error: {
      ...colorPalette.error,
    },
    border: {
      main: colorPalette.basic[700],
    },
    background: {
      paper: colorPalette.main[500],
      default: colorPalette.main[500],
      grey: colorPalette.main[500],
    },
    yellow: {
      ...colorPalette.yellow,
    },
    main: {
      ...colorPalette.main,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'SF Pro Display',
    ].join(','),
    fontSize: 14,
    title: {
      fontSize: 20,
      fontWeight: 700,
    },
    noteGrey: {
      fontSize: 14,
      color: colorPalette.basic[600],
      fontWeight: 400,
    },
    noteWhite: {
      fontSize: 14,
      color: colorPalette.basic[100],
      fontWeight: 400,
    },
    typo_20_500: {
      fontSize: 20,
      fontWeight: 500,
    },
    note12: {
      fontSize: 12,
      fontWeight: 500,
      color: colorPalette.basic[500],
    },
    errorNote: {
      fontSize: 12,
      fontWeight: 500,
      color: colorPalette.error[500],
    },
    note1: {
      fontSize: 12,
      fontWeight: 500,
      color: colorPalette.basic[100],
    },
    text1: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  spacing: [0, 4, 12, 16, 32, 64],
})

const theme = createTheme(getDesignTokens('dark'))

export { theme }
