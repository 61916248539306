import { produce } from 'immer'
import { types } from './constants'

//initial state of the dashboard page
export const initialState = {
  isRequesting: false,
  error: {},
  maintenanceMode: false,
  isFetchMode: false,
  reports: {
    report: {
      data: [],
      isLoading: false,
    },
    autoLock: {
      data: [],
      isLoading: false,
    },
    subpage: {
      data: [],
      isLoading: false,
    },
  },
}

const settingsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.TURN_OFF_NOTIFICATION:
        draft.isRequesting = true
        break
      case types.TURN_OFF_NOTIFICATION_SUCCESS:
        draft.isRequesting = false
        break
      case types.TURN_OFF_NOTIFICATION_FAILED:
        draft.isRequesting = false
        draft.error = {}
        break
      case types.GET_REPORTS_SETTING:
        draft.reports.report.isLoading = true
        break
      case types.GET_REPORTS_SETTING_SUCCESS:
        draft.reports.report.data = action.payload.data
        draft.reports.report.isLoading = false
        break
      case types.GET_REPORTS_SETTING_FAILED:
        draft.reports.report.isLoading = false
        draft.error = {}
        break
      case types.GET_AUTO_LOCK_SETTING:
        draft.reports.autoLock.isLoading = true
        break
      case types.GET_AUTO_LOCK_SETTING_SUCCESS:
        draft.reports.autoLock.data = action.payload.data
        draft.reports.autoLock.isLoading = false
        break
      case types.GET_AUTO_LOCK_SETTING_FAILED:
        draft.reports.autoLock.isLoading = false
        draft.error = {}
        break
      case types.GET_SUB_PAGE_SETTING:
        draft.reports.subpage.isLoading = true
        break
      case types.GET_SUB_PAGE_SETTING_SUCCESS:
        draft.reports.subpage.data = action.payload.data
        draft.reports.subpage.isLoading = false
        break
      case types.GET_SUB_PAGE_SETTING_FAILED:
        draft.reports.subpage.isLoading = false
        draft.error = {}
        break
      case types.FETCH_MAINTENANCE_MODE:
        draft.isFetchMode = true
        break
      case types.FETCH_MAINTENANCE_MODE_SUCCESS:
        draft.isFetchMode = false
        draft.maintenanceMode = action.payload
        break
      default:
        break
    }
  })

export default settingsReducer
