import { types } from './constants'

// {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListReports = (payload) => ({
  type: types.GET_LIST_REPORTS_REQUEST,
  payload,
})

export const getListReportsSuccess = (payload) => ({
  type: types.GET_LIST_REPORTS_SUCCESS,
  payload,
})

export const getListReportsFailed = (payload) => ({
  type: types.GET_LIST_REPORTS_FAILED,
  payload,
})

export function getReportStatisticRequest(data, callback) {
  return {
    type: types.GET_REPORT_STATISTIC_REQUEST,
    payload: { data, callback },
  }
}

export function getReportStatisticSuccess(data) {
  return {
    type: types.GET_REPORT_STATISTIC_SUCCESS,
    payload: data,
  }
}

export function getReportStatisticFail(error) {
  return {
    type: types.GET_REPORT_STATISTIC_FAIL,
    payload: { error },
  }
}

export function getReportSummaryRequest(data, callback) {
  return {
    type: types.GET_REPORT_SUMMARY_REQUEST,
    payload: { data, callback },
  }
}

export function getReportSummarySuccess(data) {
  return {
    type: types.GET_REPORT_SUMMARY_SUCCESS,
    payload: data,
  }
}

export function getReportSummaryFail(error) {
  return {
    type: types.GET_REPORT_SUMMARY_FAIL,
    payload: { error },
  }
}

export const getDetailReports = (id, navigate, callback) => ({
  type: types.GET_DETAIL_REPORTS_REQUEST,
  payload: { id, navigate, callback },
})

export const getDetailReportsSuccess = (payload) => ({
  type: types.GET_DETAIL_REPORTS_SUCCESS,
  payload,
})

export const getDetailReportsFailed = (payload) => ({
  type: types.GET_DETAIL_REPORTS_FAILED,
  payload,
})

export const updateStatusReport = (id, dataSend) => {
  return {
    type: types.UPDATE_STATUS_REPORTS_REQUEST,
    payload: { id, dataSend },
  }
}

export const updateStatusReportSuccess = (payload) => ({
  type: types.UPDATE_STATUS_REPORTS_SUCCESS,
  payload,
})

export const updateStatusReportFailed = (payload) => ({
  type: types.UPDATE_STATUS_REPORTS_FAILED,
  payload,
})

export const updateActionReport = (id, dataSend) => {
  return {
    type: types.UPDATE_ACTION_REPORTS_REQUEST,
    payload: { id, dataSend },
  }
}

export const updateActionReportSuccess = (payload) => ({
  type: types.UPDATE_ACTION_REPORTS_SUCCESS,
  payload,
})

export const updateActionReportFailed = (payload) => ({
  type: types.UPDATE_ACTION_REPORTS_FAILED,
  payload,
})