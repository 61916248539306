import {
  RECIPIENT_TYPE_USERS,
  SEND_TO_ALL_USERS,
  SEND_TO_CUSTOMIZE,
} from 'constants/notify/consts'
import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'

const authBaseUrl = '/system-notifications'
// params = {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListNotify = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings(authBaseUrl, params))
}

export const getDetailNotify = (id) => {
  return axiosClient.get(`${authBaseUrl}/${id}`)
}

export const deleteNotify = (id) => {
  return axiosClient.delete(`${authBaseUrl}/${id}`)
}

export const addNewNotify = (data) => {
  return axiosClient.post(authBaseUrl, data)
}

export const updateNotify = (id, data) => {
  return axiosClient.put(`${authBaseUrl}/${id}`, data)
}

export const formatDataSubmit = (data) => {
  let sendTo = SEND_TO_ALL_USERS
  let sendToData = {}
  if (data.sendTo.key === SEND_TO_CUSTOMIZE) {
    sendTo = `by_${data.recipientType.key}`
    sendToData[data.recipientType.key] =
      data.recipientType.key === RECIPIENT_TYPE_USERS
        ? data.sendToData.map((item) => item.id)
        : data.sendToData.map((item) => item.code)
  }

  return {
    scheduledAt: data.scheduledAt,
    note: data.note,
    title: data.title,
    subtitle: data.subtitle,
    content: data.content,
    send_to: sendTo,
    send_to_data: sendToData,
  }
}
