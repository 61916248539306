import dayjs from 'dayjs'

export const buildUrlWithQueryStrings = (endpoint, queryObject = {}) => {
  const queryString = Object.keys(queryObject)
    .map((key) => {
      if (Array.isArray(queryObject[key])) {
        return queryObject[key].map((x) => `${key}=${x}`).join('&')
      }
      return `${key}=${encodeURIComponent(queryObject[key])}`
    })
    .join('&')
  return `${endpoint}?${queryString}`
}

export const buildSort = (sorts = []) => {
  const sortsString = sorts
    .slice(0, 1)
    .map((sort) => {
      const indicator = sort.asc ? '' : '-'
      return `${indicator}${sort.field}`
    })
    .join('')

  return sortsString !== '' ? sortsString : undefined
}

export function a11yProps(tabId) {
  return {
    id: `full-width-tab-${tabId}`,
    'aria-controls': `full-width-tabpanel-${tabId}`,
  }
}

export const parseDate = (date, format = 'D MMMM, YYYY') => {
  return dayjs(date).locale('en').format(format)
}

export const parseDateTime = (date, format = 'HH:mm:ss MMMM DD, YYYY') => {
  return dayjs(date).locale('en').format(format)
}

export function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B'
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K'
  } else {
    return num.toString()
  }
}

export const copyToClipboard = (link, callback = () => {}) => {
  navigator.clipboard
    .writeText(link)
    .then(() => {
      callback('success')
    })
    .catch(() => {
      callback('error')
    })
}
