import { types } from './constants'

export function fetchAutoLockedRequest() {
  return {
    type: types.FETCH_STATISTIC_AUTOLOCK_REQUEST,
  }
}

export function fetchAutoLockedSuccess(data) {
  return {
    type: types.FETCH_STATISTIC_AUTOLOCK_SUCCESS,
    payload: data,
  }
}

export function fetchAutoLockedFail(error) {
  return {
    type: types.FETCH_STATISTIC_AUTOLOCK_FAIL,
    payload: error,
  }
}
export function fetchStatisticReportRequest() {
  return {
    type: types.FETCH_STATISTIC_REPORT_REQUEST,
  }
}

export function fetchStatisticReportSuccess(data) {
  return {
    type: types.FETCH_STATISTIC_REPORT_SUCCESS,
    payload: data,
  }
}

export function fetchStatisticReportFail(error) {
  return {
    type: types.FETCH_STATISTIC_REPORT_FAIL,
    payload: error,
  }
}

export function fetchMetricsOverviewRequest() {
  return {
    type: types.FETCH_METRICS_OVERVIEW_REQUEST,
  }
}

export function fetchMetricsOverviewSuccess(data) {
  return {
    type: types.FETCH_METRICS_OVERVIEW_SUCCESS,
    payload: data,
  }
}

export function fetchMetricsOverviewFail(error) {
  return {
    type: types.FETCH_METRICS_OVERVIEW_FAIL,
    payload: error,
  }
}

export function fetchUsersActiveRequest() {
  return {
    type: types.FETCH_USERS_ACTIVE_REQUEST,
  }
}

export function fetchUsersActiveSuccess(data) {
  return {
    type: types.FETCH_USERS_ACTIVE_SUCCESS,
    payload: data,
  }
}

export function fetchUsersActiveFail(error) {
  return {
    type: types.FETCH_USERS_ACTIVE_FAIL,
    payload: error,
  }
}

export function fetchUsersAverageRequest() {
  return {
    type: types.FETCH_USERS_AVERAGE_REQUEST,
  }
}

export function fetchUsersAverageSuccess(data) {
  return {
    type: types.FETCH_USERS_AVERAGE_SUCCESS,
    payload: data,
  }
}

export function fetchUsersAverageFail(error) {
  return {
    type: types.FETCH_USERS_AVERAGE_FAIL,
    payload: error,
  }
}
