import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'

// const authBaseUrl = '/admin'

// params = {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListReports = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings(`/reports`, params))
}

export const getReportsStatistic = () => {
  return axiosClient.get('reports/statistic')
}
export const getReportsSummary = () => {
  return axiosClient.get('reports/summary')
}

export const getDetailReports = (id) => {
  return axiosClient.get(`reports/${id}`)
}

export const updateStatusReport = (id, data) => {
  return axiosClient.patch(`reports/${id}`, { status: data })
}

export const updateActionReport = (id, data) => {
  return axiosClient.patch(`reports/${id}/actions?type=${data}`)
}
