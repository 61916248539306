import * as React from 'react'
import { Menu, MenuItem, ListItemIcon, Box, useTheme } from '@mui/material'

export default function DropdownMenu({
  anchorEl,
  onClickOption,
  onClose,
  menuItems = [],
}) {
  const open = Boolean(anchorEl)
  const theme = useTheme()

  return (
    <Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
        sx={{
          '& .MuiList-root': {
            backgroundColor: theme.palette.background.default,
          },
          '& .MuiPaper-root': { borderRadius: '8px' },
        }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            onClick={() => onClickOption(menuItem.id)}
            sx={{
              fontSize: 14,
              color: menuItem.color ? menuItem.color : '#fff',
            }}
          >
            {menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
            {menuItem.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
