import { types } from './constants'

export function getMyAccountRequest(data, callback) {
  return {
    type: types.GET_MY_ACCOUNT_REQUEST,
    payload: { data, callback },
  }
}

export function getMyAccountSuccess(data) {
  return {
    type: types.GET_MY_ACCOUNT_SUCCESS,
    payload: data,
  }
}

export function getMyAccountFail(error) {
  return {
    type: types.GET_MY_ACCOUNT_FAIL,
    payload: { error },
  }
}

export function updateAccountRequest(data, callback) {
  return {
    type: types.UPDATE_ACCOUNT_REQUEST,
    payload: { data, callback },
  }
}

export function updateAccountSuccess(data) {
  return {
    type: types.UPDATE_ACCOUNT_SUCCESS,
    payload: data,
  }
}

export function updateAccountFail(error) {
  return {
    type: types.UPDATE_ACCOUNT_FAIL,
    payload: { error },
  }
}

export function uploadImageRequest(data, callback) {
  return {
    type: types.UPLOAD_IMAGE_REQUEST,
    payload: { data, callback },
  }
}

export function uploadImageSuccess(data) {
  return {
    type: types.UPLOAD_IMAGE_SUCCESS,
    payload: data,
  }
}

export function uploadImageFail(error) {
  return {
    type: types.UPLOAD_IMAGE_FAIL,
    payload: { error },
  }
}

export const changePassword = (payload) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload,
})

export const changePasswordSuccess = (payload) => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  payload,
})

export const changePasswordFailed = (payload) => ({
  type: types.CHANGE_PASSWORD_FAILED,
  payload,
})
