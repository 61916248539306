import { applyMiddleware, legacy_createStore as createStore } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import { rootReducer } from 'store/reducers'
import rootSaga from 'store/sagas'
import { composeWithDevTools } from '@redux-devtools/extension';

const sagaMiddleware = createSagaMiddleware()

export default function configureStore(preloadedState) {
  const middlewares = [sagaMiddleware]

  const enhancers = [applyMiddleware(...middlewares)]
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  store.close = () => {
    store.dispatch(END)
  }
  sagaMiddleware.run(rootSaga)

  return store
}
