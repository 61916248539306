import CookieInstance from 'utils/cookie'
import { removeItem } from './localStorage'

const logout = (options = { fn: null }) => {
  const { fn } = options
  // Execute callback if have
  if (fn && typeof fn === 'function') fn()
  CookieInstance.removeCookie(process.env.REACT_APP_COOKIE_NAME)
  removeItem(process.env.REACT_APP_PRESIT_STORE)
  window.location.href = `${process.env.REACT_APP_DOMAIN_NAME}/login`
}

export default logout
