export const types = {
  GET_LIST_NOTIFY_REQUEST: 'notify/GET_LIST_NOTIFY_REQUEST',
  GET_LIST_NOTIFY_SUCCESS: 'notify/GET_LIST_NOTIFY_SUCCESS',
  GET_LIST_NOTIFY_FAILED: 'notify/GET_LIST_NOTIFY_FAILED',

  GET_DETAIL_NOTIFY_REQUEST: 'notify/GET_DETAIL_NOTIFY_REQUEST',
  GET_DETAIL_NOTIFY_SUCCESS: 'notify/GET_DETAIL_NOTIFY_SUCCESS',
  GET_DETAIL_NOTIFY_FAILED: 'notify/GET_DETAIL_NOTIFY_FAILED',

  ADD_NEW_NOTIFY_REQUEST: 'notify/ADD_NEW_NOTIFY_REQUEST',
  ADD_NEW_NOTIFY_SUCCESS: 'notify/ADD_NEW_NOTIFY_SUCCESS',
  ADD_NEW_NOTIFY_FAILED: 'notify/ADD_NEW_NOTIFY_FAILED',

  UPDATE_NOTIFY_REQUEST: 'notify/UPDATE_NOTIFY_REQUEST',
  UPDATE_NOTIFY_SUCCESS: 'notify/UPDATE_NOTIFY_SUCCESS',
  UPDATE_NOTIFY_FAILED: 'notify/UPDATE_NOTIFY_FAILED',

  DELETE_NOTIFY: 'notify/DELETE_NOTIFY',
}
