import React, { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { Box, IconButton } from '@mui/material'
import TextInput from '../TextInput'

function PasswordInput(props) {
  const { value, onChange, className, label, errorInput, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <Box sx={{ position: 'relative', width: '100%'}}>
      <TextInput
        type={showPassword ? 'text' : 'password'}
        error={!!errorInput}
        helperText={errorInput}
        label={label}
        value={value}
        onChange={onChange}
        variant="outlined"
        {...rest}
      />
      <IconButton
        sx={{
          position: 'absolute',
          right: 21,
          top: 20,
        }}
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </Box>
  )
}

export default PasswordInput
