import { all } from 'redux-saga/effects'
import loginWatcher from 'containers/auth/store/sagas'
import usersWatcher from 'containers/Users/store/sagas'
import dashboardWatcher from 'containers/Dashboard/store/sagas'
import myAccountWatcher from 'containers/MyAccount/store/sagas'
import reportsWatcher from 'containers/Reports/store/sagas'
import contentsWatcher from 'containers/Contents/store/sagas'
import settingsWatcher from 'containers/Settings/store/sagas'
import notifyWatchers from 'containers/Notify/store/sagas'

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield all([
    loginWatcher(),
    usersWatcher(),
    dashboardWatcher(),
    myAccountWatcher(),
    reportsWatcher(),
    contentsWatcher(),
    settingsWatcher(),
    notifyWatchers(),
  ])
}
