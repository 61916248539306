import dayjs from 'dayjs'
import * as FIELD from 'constants/fields'
import * as LABELS from 'constants/labels'
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  DEFAULT_SORT_DIRECTION,
} from 'constants/common'
import { Box } from '@mui/material'
import { formatNumber } from 'utils'
import deleteIcon from 'assets/images/delete.svg'

export const TABS = [
  {
    value: 'instant',
    label: 'Sent',
  },
  {
    value: 'schedule',
    label: 'Scheduled',
  },
]

export const DEFAULT_PARAMS = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE,
  sort_direction: DEFAULT_SORT_DIRECTION,
  sort_by: FIELD.SENT_AT,
}

export const DEFAULT_PARAMS_TABLE_DETAIL_CONTENT = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE,
}

export const MAPPING_TEXT_SEND_TO = {
  all_users: 'All users',
  by_user_countries: 'By user countries',
  by_user_languages: 'By user languages',
  by_user_ids: 'Specific user',
}

export const COLUMNS_SCHEDUAL = [
  {
    field: 'index',
    headerName: 'No.',
    width: 70,
    disableColumnMenu: true,
    disableColumnSorting: true,
    sortable: false,
  },
  {
    field: FIELD.ID,
    headerName: LABELS.NOTIFY_ID,
    minWidth: 100,
    flex: 1,
    renderCell(params) {
      return (
        <Box component="span" sx={{ color: '#8EF351', cursor: 'pointer' }}>
          #{params?.value}
        </Box>
      )
    },
  },
  {
    field: FIELD.SCHEDULED_AT,
    headerName: LABELS.SCHEDULED_AT,
    minWidth: 100,
    flex: 1,
    renderCell(params) {
      const formatted = params?.value
        ? dayjs(params?.value).format('HH:mm:ss MMM D, YYYY')
        : ''
      return <Box component="span">{formatted}</Box>
    },
  },
  {
    field: FIELD.CREATED_BY,
    headerName: LABELS.CREATED_BY,
    minWidth: 120,
    flex: 1,
    sortable: true,
    renderCell(params) {
      return <Box>Administrator</Box>
    },
  },
  {
    field: FIELD.CONTENT,
    headerName: LABELS.CONTENT,
    minWidth: 150,
    flex: 1,
    renderCell(params) {
      return <Box>{params.value}</Box>
    },
  },
  {
    field: FIELD.SEND_TO,
    headerName: LABELS.SEND_TO,
    minWidth: 150,
    flex: 1,
    renderCell(params) {
      return <Box>{MAPPING_TEXT_SEND_TO[params.value]}</Box>
    },
  },
]

export const COLUMNS_SENT = [
  {
    field: 'index',
    headerName: 'No.',
    width: 70,
    disableColumnMenu: true,
    disableColumnSorting: true,
    sortable: false,
  },
  {
    field: FIELD.ID,
    headerName: LABELS.NOTIFY_ID,
    minWidth: 100,
    flex: 1,
    renderCell(params) {
      return (
        <Box component="span" sx={{ color: '#8EF351', cursor: 'pointer' }}>
          #{params?.value}
        </Box>
      )
    },
  },
  {
    field: FIELD.SENT_AT,
    headerName: LABELS.SEND_AT,
    minWidth: 100,
    flex: 1,
    renderCell(params) {
      const formatted = params?.value
        ? dayjs(params?.value).format('HH:mm:ss MMM D, YYYY')
        : ''
      return <Box component="span">{formatted}</Box>
    },
  },
  {
    field: FIELD.CREATED_BY,
    headerName: LABELS.CREATED_BY,
    minWidth: 120,
    flex: 1,
    sortable: true,
    renderCell(params) {
      return <Box>Administrator</Box>
    },
  },
  {
    field: FIELD.CONTENT,
    headerName: LABELS.CONTENT,
    minWidth: 150,
    flex: 1,
    renderCell(params) {
      return <Box>{params.value}</Box>
    },
  },
  {
    field: FIELD.SEND_TO,
    headerName: LABELS.SEND_TO,
    minWidth: 150,
    flex: 1,
    renderCell(params) {
      return <Box>{MAPPING_TEXT_SEND_TO[params.value]}</Box>
    },
  },
  {
    field: FIELD.TOTAL_SENT,
    headerName: LABELS.SENT,
    minWidth: 150,
    flex: 1,
    renderCell(params) {
      return <Box>{formatNumber(Number(params.value))}</Box>
    },
  },
]

export const DELETE_MENU = {
  id: 'delete',
  title: 'Delete this notify',
  icon: <img src={deleteIcon} alt="deleteIcon" />,
  color: '#F5222D',
}

export const TYPE_NOTIFICATION = {
  instant: 'Instant notify',
  schedule: 'Schedule notify',
}

export const TAG_MAPPING_HTML = {
  user_name:
    '<span class="tag-user_name" style="color:#FF8900;width:fit-content;margin:0;font-size:16px;">{user_name}</span>&nbsp;',
  user_email:
    '<span class="tag-user_name" style="color:#FF8900;width:fit-content;margin:0;font-size:16px;">{user_email}</span>&nbsp;',
}

export const SEND_TO_ALL_USERS = 'all_users'
export const SEND_TO_CUSTOMIZE = 'customize'

export const RECIPIENT_TYPE_COUNTRIES = 'user_countries'
export const RECIPIENT_TYPE_LANGUAGES = 'user_languages'
export const RECIPIENT_TYPE_USERS = 'user_ids'

export const SEND_TO_LIST = [
  { key: SEND_TO_ALL_USERS, label: MAPPING_TEXT_SEND_TO.all_users },
  { key: SEND_TO_CUSTOMIZE, label: 'Customize' },
]

export const RECIPIENTS_TYPES = [
  { key: RECIPIENT_TYPE_COUNTRIES, label: MAPPING_TEXT_SEND_TO.by_user_countries },
  { key: RECIPIENT_TYPE_LANGUAGES, label: MAPPING_TEXT_SEND_TO.by_user_languages },
  { key: RECIPIENT_TYPE_USERS, label: MAPPING_TEXT_SEND_TO.by_user_ids },
]

export const COUNTRIES = [
  {
    code: 'AD',
    label: 'Andorra',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
  },
  {
    code: 'AF',
    label: 'Afghanistan',
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
  },
  {
    code: 'AI',
    label: 'Anguilla',
  },
  {
    code: 'AL',
    label: 'Albania',
  },
  {
    code: 'AM',
    label: 'Armenia',
  },
  {
    code: 'AO',
    label: 'Angola',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
  },
  {
    code: 'AR',
    label: 'Argentina',
  },
  {
    code: 'AS',
    label: 'American Samoa',
  },
  {
    code: 'AT',
    label: 'Austria',
  },
  {
    code: 'AU',
    label: 'Australia',
  },
  {
    code: 'AW',
    label: 'Aruba',
  },
  {
    code: 'AX',
    label: 'Alland Islands',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
  },
  {
    code: 'BB',
    label: 'Barbados',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
  },
  {
    code: 'BE',
    label: 'Belgium',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
  },
  {
    code: 'BH',
    label: 'Bahrain',
  },
  {
    code: 'BI',
    label: 'Burundi',
  },
  {
    code: 'BJ',
    label: 'Benin',
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
  },
  {
    code: 'BM',
    label: 'Bermuda',
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    code: 'BO',
    label: 'Bolivia',
  },
  {
    code: 'BR',
    label: 'Brazil',
  },
  {
    code: 'BS',
    label: 'Bahamas',
  },
  {
    code: 'BT',
    label: 'Bhutan',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
  },
  {
    code: 'BW',
    label: 'Botswana',
  },
  {
    code: 'BY',
    label: 'Belarus',
  },
  {
    code: 'BZ',
    label: 'Belize',
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
  },
  {
    code: 'CH',
    label: 'Switzerland',
  },
  {
    code: 'CI',
    label: "Cote d'Ivoire",
  },
  {
    code: 'CK',
    label: 'Cook Islands',
  },
  {
    code: 'CL',
    label: 'Chile',
  },
  {
    code: 'CM',
    label: 'Cameroon',
  },
  {
    code: 'CN',
    label: 'China',
  },
  {
    code: 'CO',
    label: 'Colombia',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
  },
  {
    code: 'CU',
    label: 'Cuba',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
  },
  {
    code: 'CW',
    label: 'Curacao',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
  },
  {
    code: 'CY',
    label: 'Cyprus',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
  },
  {
    code: 'DE',
    label: 'Germany',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
  },
  {
    code: 'DK',
    label: 'Denmark',
  },
  {
    code: 'DM',
    label: 'Dominica',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
  },
  {
    code: 'DZ',
    label: 'Algeria',
  },
  {
    code: 'EC',
    label: 'Ecuador',
  },
  {
    code: 'EE',
    label: 'Estonia',
  },
  {
    code: 'EG',
    label: 'Egypt',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
  },
  {
    code: 'ER',
    label: 'Eritrea',
  },
  {
    code: 'ES',
    label: 'Spain',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
  },
  {
    code: 'FI',
    label: 'Finland',
  },
  {
    code: 'FJ',
    label: 'Fiji',
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'GA',
    label: 'Gabon',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
  },
  {
    code: 'GD',
    label: 'Grenada',
  },
  {
    code: 'GE',
    label: 'Georgia',
  },
  {
    code: 'GF',
    label: 'French Guiana',
  },
  {
    code: 'GG',
    label: 'Guernsey',
  },
  {
    code: 'GH',
    label: 'Ghana',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
  },
  {
    code: 'GL',
    label: 'Greenland',
  },
  {
    code: 'GM',
    label: 'Gambia',
  },
  {
    code: 'GN',
    label: 'Guinea',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
  },
  {
    code: 'GR',
    label: 'Greece',
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'GT',
    label: 'Guatemala',
  },
  {
    code: 'GU',
    label: 'Guam',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    label: 'Guyana',
  },
  {
    code: 'HK',
    label: 'Hong Kong',
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HN',
    label: 'Honduras',
  },
  {
    code: 'HR',
    label: 'Croatia',
  },
  {
    code: 'HT',
    label: 'Haiti',
  },
  {
    code: 'HU',
    label: 'Hungary',
  },
  {
    code: 'ID',
    label: 'Indonesia',
  },
  {
    code: 'IE',
    label: 'Ireland',
  },
  {
    code: 'IL',
    label: 'Israel',
  },
  {
    code: 'IM',
    label: 'Isle of Man',
  },
  {
    code: 'IN',
    label: 'India',
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
  },
  {
    code: 'IQ',
    label: 'Iraq',
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
  },
  {
    code: 'IS',
    label: 'Iceland',
  },
  {
    code: 'IT',
    label: 'Italy',
  },
  {
    code: 'JE',
    label: 'Jersey',
  },
  {
    code: 'JM',
    label: 'Jamaica',
  },
  {
    code: 'JO',
    label: 'Jordan',
  },
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'KE',
    label: 'Kenya',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
  },
  {
    code: 'KH',
    label: 'Cambodia',
  },
  {
    code: 'KI',
    label: 'Kiribati',
  },
  {
    code: 'KM',
    label: 'Comoros',
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
  },
  {
    code: 'KR',
    label: 'Korea, Republic of',
  },
  {
    code: 'KW',
    label: 'Kuwait',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
  },
  {
    code: 'LB',
    label: 'Lebanon',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
  },
  {
    code: 'LR',
    label: 'Liberia',
  },
  {
    code: 'LS',
    label: 'Lesotho',
  },
  {
    code: 'LT',
    label: 'Lithuania',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
  },
  {
    code: 'LV',
    label: 'Latvia',
  },
  {
    code: 'LY',
    label: 'Libya',
  },
  {
    code: 'MA',
    label: 'Morocco',
  },
  {
    code: 'MC',
    label: 'Monaco',
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
  },
  {
    code: 'ME',
    label: 'Montenegro',
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
  },
  {
    code: 'MG',
    label: 'Madagascar',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
  },
  {
    code: 'ML',
    label: 'Mali',
  },
  {
    code: 'MM',
    label: 'Myanmar',
  },
  {
    code: 'MN',
    label: 'Mongolia',
  },
  {
    code: 'MO',
    label: 'Macao',
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    code: 'MQ',
    label: 'Martinique',
  },
  {
    code: 'MR',
    label: 'Mauritania',
  },
  {
    code: 'MS',
    label: 'Montserrat',
  },
  {
    code: 'MT',
    label: 'Malta',
  },
  {
    code: 'MU',
    label: 'Mauritius',
  },
  {
    code: 'MV',
    label: 'Maldives',
  },
  {
    code: 'MW',
    label: 'Malawi',
  },
  {
    code: 'MX',
    label: 'Mexico',
  },
  {
    code: 'MY',
    label: 'Malaysia',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
  },
  {
    code: 'NA',
    label: 'Namibia',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
  },
  {
    code: 'NE',
    label: 'Niger',
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
  },
  {
    code: 'NG',
    label: 'Nigeria',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
  },
  {
    code: 'NL',
    label: 'Netherlands',
  },
  {
    code: 'NO',
    label: 'Norway',
  },
  {
    code: 'NP',
    label: 'Nepal',
  },
  {
    code: 'NR',
    label: 'Nauru',
  },
  {
    code: 'NU',
    label: 'Niue',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
  },
  {
    code: 'OM',
    label: 'Oman',
  },
  {
    code: 'PA',
    label: 'Panama',
  },
  {
    code: 'PE',
    label: 'Peru',
  },
  {
    code: 'PF',
    label: 'French Polynesia',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
  },
  {
    code: 'PH',
    label: 'Philippines',
  },
  {
    code: 'PK',
    label: 'Pakistan',
  },
  {
    code: 'PL',
    label: 'Poland',
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
  },
  {
    code: 'PT',
    label: 'Portugal',
  },
  {
    code: 'PW',
    label: 'Palau',
  },
  {
    code: 'PY',
    label: 'Paraguay',
  },
  {
    code: 'QA',
    label: 'Qatar',
  },
  {
    code: 'RE',
    label: 'Reunion',
  },
  {
    code: 'RO',
    label: 'Romania',
  },
  {
    code: 'RS',
    label: 'Serbia',
  },
  {
    code: 'RU',
    label: 'Russian Federation',
  },
  {
    code: 'RW',
    label: 'Rwanda',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
  },
  {
    code: 'SC',
    label: 'Seychelles',
  },
  {
    code: 'SD',
    label: 'Sudan',
  },
  {
    code: 'SE',
    label: 'Sweden',
  },
  {
    code: 'SG',
    label: 'Singapore',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
  },
  {
    code: 'SI',
    label: 'Slovenia',
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SK',
    label: 'Slovakia',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
  },
  {
    code: 'SM',
    label: 'San Marino',
  },
  {
    code: 'SN',
    label: 'Senegal',
  },
  {
    code: 'SO',
    label: 'Somalia',
  },
  {
    code: 'SR',
    label: 'Suriname',
  },
  {
    code: 'SS',
    label: 'South Sudan',
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
  },
  {
    code: 'SV',
    label: 'El Salvador',
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
  },
  {
    code: 'TD',
    label: 'Chad',
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
  },
  {
    code: 'TG',
    label: 'Togo',
  },
  {
    code: 'TH',
    label: 'Thailand',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
  },
  {
    code: 'TK',
    label: 'Tokelau',
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
  },
  {
    code: 'TN',
    label: 'Tunisia',
  },
  {
    code: 'TO',
    label: 'Tonga',
  },
  {
    code: 'TR',
    label: 'Turkey',
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
  },
  {
    code: 'TW',
    label: 'Taiwan',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
  },
  {
    code: 'UA',
    label: 'Ukraine',
  },
  {
    code: 'UG',
    label: 'Uganda',
  },
  {
    code: 'US',
    label: 'United States',
  },
  {
    code: 'UY',
    label: 'Uruguay',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'VE',
    label: 'Venezuela',
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
  },
  {
    code: 'VN',
    label: 'Vietnam',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
  },
  {
    code: 'WS',
    label: 'Samoa',
  },
  {
    code: 'XK',
    label: 'Kosovo',
  },
  {
    code: 'YE',
    label: 'Yemen',
  },
  {
    code: 'YT',
    label: 'Mayotte',
  },
  {
    code: 'ZA',
    label: 'South Africa',
  },
  {
    code: 'ZM',
    label: 'Zambia',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
  },
]

export const LANGUAGES = [
  { code: 'aa', name: 'Afar' },
  { code: 'ab', name: 'Abkhazian' },
  { code: 'ae', name: 'Avestan' },
  { code: 'af', name: 'Afrikaans' },
  { code: 'ak', name: 'Akan' },
  { code: 'am', name: 'Amharic' },
  { code: 'an', name: 'Aragonese' },
  { code: 'ar', name: 'Arabic' },
  { code: 'as', name: 'Assamese' },
  { code: 'av', name: 'Avaric' },
  { code: 'ay', name: 'Aymara' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'ba', name: 'Bashkir' },
  { code: 'be', name: 'Belarusian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'bh', name: 'Bihari languages' },
  { code: 'bi', name: 'Bislama' },
  { code: 'bm', name: 'Bambara' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bo', name: 'Tibetan' },
  { code: 'br', name: 'Breton' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'ca', name: 'Catalan; Valencian' },
  { code: 'ce', name: 'Chechen' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'co', name: 'Corsican' },
  { code: 'cr', name: 'Cree' },
  { code: 'cs', name: 'Czech' },
  {
    code: 'cu',
    name: 'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  },
  { code: 'cv', name: 'Chuvash' },
  { code: 'cy', name: 'Welsh' },
  { code: 'da', name: 'Danish' },
  { code: 'de', name: 'German' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian' },
  { code: 'dz', name: 'Dzongkha' },
  { code: 'ee', name: 'Ewe' },
  { code: 'el', name: 'Greek, Modern (1453-)' },
  { code: 'en', name: 'English' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'es', name: 'Spanish; Castilian' },
  { code: 'et', name: 'Estonian' },
  { code: 'eu', name: 'Basque' },
  { code: 'fa', name: 'Persian' },
  { code: 'ff', name: 'Fulah' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fj', name: 'Fijian' },
  { code: 'fo', name: 'Faroese' },
  { code: 'fr', name: 'French' },
  { code: 'fy', name: 'Western Frisian' },
  { code: 'ga', name: 'Irish' },
  { code: 'gd', name: 'Gaelic; Scomttish Gaelic' },
  { code: 'gl', name: 'Galician' },
  { code: 'gn', name: 'Guarani' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'gv', name: 'Manx' },
  { code: 'ha', name: 'Hausa' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'ho', name: 'Hiri Motu' },
  { code: 'hr', name: 'Croatian' },
  { code: 'ht', name: 'Haitian; Haitian Creole' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'hy', name: 'Armenian' },
  { code: 'hz', name: 'Herero' },
  {
    code: 'ia',
    name: 'Interlingua (International Auxiliary Language Association)',
  },
  { code: 'id', name: 'Indonesian' },
  { code: 'ie', name: 'Interlingue; Occidental' },
  { code: 'ig', name: 'Igbo' },
  { code: 'ii', name: 'Sichuan Yi; Nuosu' },
  { code: 'ik', name: 'Inupiaq' },
  { code: 'io', name: 'Ido' },
  { code: 'is', name: 'Icelandic' },
  { code: 'it', name: 'Italian' },
  { code: 'iu', name: 'Inuktitut' },
  { code: 'ja', name: 'Japanese' },
  { code: 'jv', name: 'Javanese' },
  { code: 'ka', name: 'Georgian' },
  { code: 'kg', name: 'Kongo' },
  { code: 'ki', name: 'Kikuyu; Gikuyu' },
  { code: 'kj', name: 'Kuanyama; Kwanyama' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'kl', name: 'Kalaallisut; Greenlandic' },
  { code: 'km', name: 'Central Khmer' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ko', name: 'Korean' },
  { code: 'kr', name: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'ku', name: 'Kurdish' },
  { code: 'kv', name: 'Komi' },
  { code: 'kw', name: 'Cornish' },
  { code: 'ky', name: 'Kirghiz; Kyrgyz' },
  { code: 'la', name: 'Latin' },
  { code: 'lb', name: 'Luxembourgish; Letzeburgesch' },
  { code: 'lg', name: 'Ganda' },
  { code: 'li', name: 'Limburgan; Limburger; Limburgish' },
  { code: 'ln', name: 'Lingala' },
  { code: 'lo', name: 'Lao' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lu', name: 'Luba-Katanga' },
  { code: 'lv', name: 'Latvian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'mh', name: 'Marshallese' },
  { code: 'mi', name: 'Maori' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'mr', name: 'Marathi' },
  { code: 'ms', name: 'Malay' },
  { code: 'mt', name: 'Maltese' },
  { code: 'my', name: 'Burmese' },
  { code: 'na', name: 'Nauru' },
  {
    code: 'nb',
    name: 'Bokmål, Norwegian; Norwegian Bokmål',
  },
  { code: 'nd', name: 'Ndebele, North; North Ndebele' },
  { code: 'ne', name: 'Nepali' },
  { code: 'ng', name: 'Ndonga' },
  { code: 'nl', name: 'Dutch; Flemish' },
  { code: 'nn', name: 'Norwegian Nynorsk; Nynorsk, Norwegian' },
  { code: 'no', name: 'Norwegian' },
  { code: 'nr', name: 'Ndebele, South; South Ndebele' },
  { code: 'nv', name: 'Navajo; Navaho' },
  { code: 'ny', name: 'Chichewa; Chewa; Nyanja' },
  { code: 'oc', name: 'Occitan (post 1500)' },
  { code: 'oj', name: 'Ojibwa' },
  { code: 'om', name: 'Oromo' },
  { code: 'or', name: 'Oriya' },
  { code: 'os', name: 'Ossetian; Ossetic' },
  { code: 'pa', name: 'Panjabi; Punjabi' },
  { code: 'pi', name: 'Pali' },
  { code: 'pl', name: 'Polish' },
  { code: 'ps', name: 'Pushto; Pashto' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'qu', name: 'Quechua' },
  { code: 'rm', name: 'Romansh' },
  { code: 'rn', name: 'Rundi' },
  { code: 'ro', name: 'Romanian; Moldavian; Moldovan' },
  { code: 'ru', name: 'Russian' },
  { code: 'rw', name: 'Kinyarwanda' },
  { code: 'sa', name: 'Sanskrit' },
  { code: 'sc', name: 'Sardinian' },
  { code: 'sd', name: 'Sindhi' },
  { code: 'se', name: 'Northern Sami' },
  { code: 'sg', name: 'Sango' },
  { code: 'si', name: 'Sinhala; Sinhalese' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'sm', name: 'Samoan' },
  { code: 'sn', name: 'Shona' },
  { code: 'so', name: 'Somali' },
  { code: 'sq', name: 'Albanian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'ss', name: 'Swati' },
  { code: 'st', name: 'Sotho, Southern' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sv', name: 'Swedish' },
  { code: 'sw', name: 'Swahili' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'tg', name: 'Tajik' },
  { code: 'th', name: 'Thai' },
  { code: 'ti', name: 'Tigrinya' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'tn', name: 'Tswana' },
  { code: 'to', name: 'Tonga (Tonga Islands)' },
  { code: 'tr', name: 'Turkish' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tt', name: 'Tatar' },
  { code: 'tw', name: 'Twi' },
  { code: 'ty', name: 'Tahitian' },
  { code: 'ug', name: 'Uighur; Uyghur' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'uz', name: 'Uzbek' },
  { code: 've', name: 'Venda' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'vo', name: 'Volapük' },
  { code: 'wa', name: 'Walloon' },
  { code: 'wo', name: 'Wolof' },
  { code: 'xh', name: 'Xhosa' },
  { code: 'yi', name: 'Yiddish' },
  { code: 'yo', name: 'Yoruba' },
  { code: 'za', name: 'Zhuang; Chuang' },
  { code: 'zh', name: 'Chinese' },
  { code: 'zu', name: 'Zulu' },
]
