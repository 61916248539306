export const ID = 'id'
export const USER_ID = 'user_id'
export const USERNAME = 'username'
export const FULL_NAME = 'full_name'
export const STATUS = 'status'
export const ACCOUNT_TYPE = 'account_type'
export const REPORT_HEALTH = 'report_health'
export const FOLLOWERS = 'total_followers'
export const IS_ONLINE = 'is_online'
export const LOCKED_AT = 'locked_at'
export const REPORTS_GOT = 'reports_got'
export const DELETED_AT = 'deleted_at'
export const DELETED_BY = 'deleted_by'
export const REPORT_ID = 'id'
export const FROM_USER = 'reporter'
export const RECEIVED_AT = 'created_at'
export const ASSIGNED_TO = 'assigned_to_user'
export const REASON_AND_DETAILS = 'reason'
export const TOP = 'top'
export const CATEGORY = 'category'
export const TOTAL_REPORTS = 'total_reports'
export const LOCK_TYPE = 'lock_type'
export const TYPE = 'TYPE'
export const OWNER_USER = 'owner_user'
export const NUMBER_OF_REPORTS = 'number_of_reports'
export const REPORT_TOPICS = 'report_topics'
export const SENT_AT = 'sent_at'
export const SCHEDULED_AT = 'scheduled_at'
export const CREATED_BY = 'created_by'
export const CONTENT = 'content'
export const SEND_TO = 'send_to'
export const TOTAL_SENT = 'total_sent'