import { takeLatest, call, put, select } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'
import {
  getDatePickerState,
  getEndDatePickerState,
} from 'containers/cache/store/selectors'

function* fetchAutoLockedSaga() {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const response = yield call(
      services.getStatisticAutoLock,
      startDate,
      endDate
    )
    const data = response.data.data
    yield put(actions.fetchAutoLockedSuccess(data))
  } catch (error) {
    yield put(actions.fetchAutoLockedFail(error))
  }
}

function* fetchStatisticReportSaga() {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const response = yield call(services.getStatisticReport, startDate, endDate)
    const data = response.data.data
    yield put(actions.fetchStatisticReportSuccess(data))
  } catch (error) {
    yield put(actions.fetchStatisticReportFail(error))
  }
}

function* fetchMetricsOverviewSaga() {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const response = yield call(services.getMetricsOverview, startDate, endDate)
    const data = response.data.data
    yield put(actions.fetchMetricsOverviewSuccess(data))
  } catch (error) {
    yield put(actions.fetchMetricsOverviewFail(error))
  }
}

function* fetchUsersActiveSaga(action) {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const response = yield call(services.getUserActive, startDate, endDate)
    const data = response.data.data
    yield put(actions.fetchUsersActiveSuccess(data))
  } catch (error) {
    yield put(actions.fetchUsersActiveFail(error))
  }
}

function* fetchUsersAverageSaga() {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const response = yield call(services.getUserAverage, startDate, endDate)
    const data = response.data.data
    yield put(actions.fetchUsersAverageSuccess(data))
  } catch (error) {
    yield put(actions.fetchUsersAverageFail(error))
  }
}

export default function* dashboardWatcher() {
  yield takeLatest(types.FETCH_USERS_AVERAGE_REQUEST, fetchUsersAverageSaga)
  yield takeLatest(types.FETCH_USERS_ACTIVE_REQUEST, fetchUsersActiveSaga)
  yield takeLatest(
    types.FETCH_METRICS_OVERVIEW_REQUEST,
    fetchMetricsOverviewSaga
  )
  yield takeLatest(types.FETCH_STATISTIC_AUTOLOCK_REQUEST, fetchAutoLockedSaga)
  yield takeLatest(
    types.FETCH_STATISTIC_REPORT_REQUEST,
    fetchStatisticReportSaga
  )
}
