import React, { Fragment } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import privateRoutes from 'routes/routes'
import MainLayout from 'layouts/MainLayout'
import LoginPage from 'pages/SignIn'

import AuthenticatedRoute from './AuthenticatedRoute'
import Dashboard from 'pages/Dashboard'

const routes = [...privateRoutes]

const Router = (
  <Fragment>
    <Routes>
      <Route element={<AuthenticatedRoute />}>
        {routes.map(({ element: Component, children, path, ...rest }) =>
          children ? (
            <Route
              path={path}
              element={
                <MainLayout
                  title={rest.title}
                  subTitle={rest.subTitle}
                  isShowDatePicker={rest.isShowDatePicker}
                >
                  <Component {...rest} />
                </MainLayout>
              }
              key={path}
            >
              <Route index element={<Navigate to={children[0].path} />} />
              {children.map(({ element: ComponentChild, path, ...rest }) => (
                <Route
                  path={path}
                  element={<ComponentChild {...rest} />}
                  key={path}
                />
              ))}
            </Route>
          ) : (
            <Route
              path={path}
              element={
                <MainLayout
                  title={rest.title}
                  subTitle={rest.subTitle}
                  isShowDatePicker={rest.isShowDatePicker}
                >
                  <Component {...rest} />
                </MainLayout>
              }
              key={path}
            />
          )
        )}
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="*"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
    </Routes>
  </Fragment>
)

export default Router
