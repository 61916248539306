import { takeLatest, call, put, select } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'
import {
  getDatePickerState,
  getEndDatePickerState,
} from 'containers/cache/store/selectors'

import { showSnackbar } from 'containers/Snackbar/store/actions'
import {
  COUNTRIES,
  LANGUAGES,
  RECIPIENTS_TYPES,
  SEND_TO_ALL_USERS,
  SEND_TO_CUSTOMIZE,
  TAG_MAPPING_HTML,
} from 'constants/notify/consts'

export const replacePlaceholders = (template) => {
  return template.replace(/\{(user_name|user_email)\}/g, (match) => {
    switch (match) {
      case '{user_name}':
        return TAG_MAPPING_HTML.user_name
      case '{user_email}':
        return TAG_MAPPING_HTML.user_email
      default:
        // For the link, replace the dynamic part with your desired link structure
        return TAG_MAPPING_HTML.user_name
    }
  })
}

function* getListNotify(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const params = action.payload
  const startDate = yield select(getDatePickerState())
  const endDate = yield select(getEndDatePickerState())
  try {
    const payload = {
      ...params,
    }
    if (params.tab === 'sent') {
      payload.sent_at_from = startDate
      payload.sent_at_to = endDate
    }
    const { data } = yield call(services.getListNotify, payload)
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
            index: index + 1 + (params.page - 1) * params.limit,
            ...item,
          }))
        : []

    yield put(
      actions.getListNotifySuccess({
        ...data,
        data: { ...data.data, records },
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getListNotifyFailed(data))
  }
}

function* getDetailNotify(action) {
  const { id, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getDetailNotify, id)

    const content = data.content || ''
    const formatContent = replacePlaceholders(content)
    const formatData = { ...data }
    if (formatData.send_to !== SEND_TO_ALL_USERS) {
      const recipientType = RECIPIENTS_TYPES.find((item) =>
        formatData.send_to.includes(item.key)
      )
      let sendToData = []
      formatData.recipient_type = recipientType
      if (formatData.send_to === 'by_user_ids') {
        sendToData = formatData?.send_to_data?.users || []
      }
      if (formatData.send_to === 'by_user_languages') {
        sendToData = LANGUAGES.filter((item) =>
          formatData.send_to_data.user_languages.includes(item.code)
        )
      }
      if (formatData.send_to === 'by_user_countries') {
        sendToData = COUNTRIES.filter((item) =>
          formatData.send_to_data.user_countries.includes(item.code)
        )
      }
      formatData.send_to_data = sendToData
      formatData.send_to = { key: SEND_TO_CUSTOMIZE, label: 'Customize' }
    } else {
      formatData.send_to = { key: SEND_TO_ALL_USERS, label: 'All users' }
    }
    callback && callback(formatData, formatContent)
    yield put(actions.getDetailNotifySuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getDetailNotifyFailed(data))
  }
}

function* deleteNotify(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deleteNotify, id)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Delete notify id #${id} success`,
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getDetailNotifyFailed(data))
  }
}

function* addNewNotify(action) {
  const { data, callback } = action.payload
  try {
    yield call(services.addNewNotify, data)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Add new notify  success`,
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.addNewNotifyFailed(data))
  }
}

function* updateNotify(action) {
  const { id, data, callback } = action.payload
  try {
    yield call(services.updateNotify, id, data)
    callback && callback()
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: `Add new notify  success`,
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.updateNotifyFailed(data))
  }
}

export default function* notifyWatchers() {
  yield takeLatest(types.GET_LIST_NOTIFY_REQUEST, getListNotify)
  yield takeLatest(types.GET_DETAIL_NOTIFY_REQUEST, getDetailNotify)
  yield takeLatest(types.DELETE_NOTIFY, deleteNotify)
  yield takeLatest(types.ADD_NEW_NOTIFY_REQUEST, addNewNotify)
  yield takeLatest(types.UPDATE_NOTIFY_REQUEST, updateNotify)
}
