import * as React from 'react'
import {
  CssBaseline,
  Box,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  useTheme,
} from '@mui/material'
import AdminIcon from 'assets/images/icon.svg'
import {
  MainNavigation,
  MyAccountNavigation,
  SubNavigation,
} from './Navigation'
import Topbar from './Topbar'
import SnackContainer from 'containers/Snackbar'
import { logoutRequest } from 'containers/auth/store/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import logoutIcon from 'assets/images/logout.svg'
import ConfirmationDialog from 'components/Popup'
import { Drawer } from './styles'

const MainLayout = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()

  const [open, setOpen] = React.useState(true)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const handleLogout = () => dispatch(logoutRequest(() => navigate('/login')))
  const handleCloseConfirm = () => setOpenConfirm(false)

  const handleClickSubMenu = (menuId) => {
    if (menuId === 'hideMenu') {
      toggleDrawer()
    }
    if (menuId === 'logout') {
      setOpenConfirm(true)
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <img src={AdminIcon} alt="adminIcon" />
            </IconButton>
            <Box sx={{ display: open ? 'initial' : 'none' }}>
              <Typography
                component="div"
                sx={{ color: theme.palette.primary.main, fontSize: 12 }}
              >
                Admin
              </Typography>
              <Typography
                component="div"
                sx={{ fontSize: 16, fontWeight: 700 }}
              >
                Tupme
              </Typography>
            </Box>
          </Toolbar>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <List component="nav">
              <MainNavigation />
            </List>
            <List component="nav">
              <MyAccountNavigation />
              <SubNavigation onClick={handleClickSubMenu} open={open} />
            </List>
          </Box>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: '#05070F',
        }}
      >
        <Topbar open={open} title={props.title} subTitle={props.subTitle} isShowDatePicker={!!props.isShowDatePicker} />
        <Box
          sx={{
            p: props.title === 'Settings' ? 0 : 3,
            maxHeight: 'calc(100% - 65px)',
            overflow: 'auto',
          }}
        >
          {props.children}
        </Box>
      </Box>
      <SnackContainer />
      <ConfirmationDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        onClickSubmit={handleLogout}
        title="Are you sure you want to logout?"
        icon={
          <img
            src={logoutIcon}
            alt="logout icon"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '80px',
              fontSize: '80px',
              filter:
                'invert(52%) sepia(93%) saturate(1537%) hue-rotate(1deg) brightness(101%) contrast(92%)',
            }}
          />
        }
        submitText="Logout"
      />
    </Box>
  )
}

export default MainLayout
