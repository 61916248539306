import axiosClient from 'utils/axios'

export const turnOffNotification = (data) => {
  return axiosClient.put('/application_settings', data)
}

export const fetchMaintenanceMode = () => {
  return axiosClient.get('/application_settings')
}

export const getReportsSetting = () => {
  return axiosClient.get('/report-settings/reasons')
}

export const saveReportsSetting = (data) => {
  return axiosClient.post('/report-settings/reasons', data)
}

export const getAutoLockSetting = () => {
  return axiosClient.get('/application_settings/auto_lock_settings')
}

export const saveAutoLockSetting = (data) => {
  return axiosClient.put('/application_settings/auto_lock_settings', data)
}

export const getSubpageSetting = () => {
  return axiosClient.get('/static-settings')
}

export const saveSubpageSetting = (data, pageName) => {
  return axiosClient.put(`/static-settings/${pageName}`, { content: data })
}
