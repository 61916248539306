import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import hideMenuIcon from 'assets/images/hide-menu.svg'
import openMenuIcon from 'assets/images/open-menu.svg'
import logoTupeIcon from 'assets/images/logo-tupme.svg'
import logoutIcon from 'assets/images/logout.svg'
import dashboardIcon from 'assets/images/dashboard.svg'
import peopleIcon from 'assets/images/people.svg'
import contentsIcon from 'assets/images/chart.svg'
import settingsIcon from 'assets/images/setting-2.svg'
import warningIcon from 'assets/images/warning-2.svg'
import notificationIcon from 'assets/images/notification.svg'

const MenuTitleIcon = ({ open }) => {
  return <img src={open ? hideMenuIcon : openMenuIcon} alt="menu icon" />
}

const mainNavigation = [
  {
    id: "dashboard",
    path: '/dashboard',
    icon: <img src={dashboardIcon} alt="dashboard icon" />,
    title: 'Dashboard',
  },
  {
    id: "users",
    path: '/users',
    icon: <img src={peopleIcon} alt="user icon" />,
    title: 'Users',
  },
  {
    id: 'reports',
    path: '/reports',
    icon: <img src={warningIcon} alt="warning icon" />,
    title: 'Reports',
  },
  {
    id: 'contents',
    path: '/contents/selling',
    icon: <img src={contentsIcon} alt="contents icon" />,
    title: 'Contents',
  },
  {
    id: 'notify',
    path: '/notify/instant',
    icon: <img src={notificationIcon} alt="notify icon" />,
    title: 'Push Notify',
  },
  {
    id: 'settings',
    path: '/settings',
    icon: <img src={settingsIcon} alt="settingsIcon icon" />,
    title: 'Settings',
  },
]

const myAccountNavigation = [
  {
    id: 'myAccount',
    path: '/my-account',
    icon: <img src={logoTupeIcon} alt="admin account icon" />,
    title: 'My account',
  },
]

const getSubNavigation = (open) => {
  return [
    {
      id: 'logout',
      path: '/logout',
      icon: <img src={logoutIcon} alt="logout icon" />,
      title: 'Logout',
    },
    {
      id: 'hideMenu',
      path: 'hide-menu',
      icon: <MenuTitleIcon open={open} />,
      title: 'Hidden title menu',
    },
  ]
}

const CustomListItemButton = ({
  menu,
  navigate,
  active,
  isInvertFilter = true,
}) => (
  <ListItemButton
    onClick={() => navigate(menu.path)}
    sx={{
      backgroundColor: active ? '#8EF351' : '',
      color: active ? '#000' : '',
      ':hover': {
        backgroundColor: active ? '#8EF351' : '',
      },
    }}
  >
    <ListItemIcon
      sx={{
        filter: active && isInvertFilter ? 'invert(1)' : 'initial',
        minWidth: 39,
      }}
    >
      {menu.icon}
    </ListItemIcon>
    <ListItemText primary={menu.title} />
  </ListItemButton>
)

export const MainNavigation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = React.useMemo(() => {
    if (location.pathname === '/') return '/dashboard'
    return location.pathname
  }, [location.pathname])

  return mainNavigation.map((menu, index) => {
    const active = currentPath.includes(menu.id)

    return (
      <CustomListItemButton
        menu={menu}
        navigate={navigate}
        active={active}
        key={index}
      />
    )
  })
}

export const MyAccountNavigation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = React.useMemo(() => {
    if (location.pathname === '/') return '/dashboard'
    return location.pathname
  }, [location.pathname])

  return myAccountNavigation.map((menu) => {
    const active = currentPath === menu.path
    return (
      <CustomListItemButton
        menu={menu}
        navigate={navigate}
        active={active}
        isInvertFilter={false}
        key={menu.id}
      />
    )
  })
}

export const SubNavigation = ({ onClick = () => {}, open }) => {
  const listMenu = getSubNavigation(open)
  return listMenu.map((menu) => (
    <ListItemButton onClick={() => onClick(menu.id)} key={menu.id}>
      <ListItemIcon
        sx={{
          minWidth: 39,
        }}
      >
        {menu.icon}
      </ListItemIcon>
      <ListItemText primary={menu.title} />
    </ListItemButton>
  ))
}
