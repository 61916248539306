import React, { useState } from 'react'
import { IconButton, Box } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import threeDot from 'assets/images/three-dot.svg'
import DropdownMenu from 'components/dropdown/Dropdown'

const ActionCell = ({
  menuItems,
  onClickOption,
  isOpenCollaspe,
  onToggle,
  id,
  groupId = null,
  reasonId = null,
  index,
  isShowCollaspe = false,
  reasonIndex = null,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClickOption = (optionData) => {
    onClickOption(optionData)
    setAnchorEl(null)
  }

  const handleCLickDropdown = (event, id) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          sx={{ mr: 2 }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleCLickDropdown}
        >
          <img src={threeDot} alt="three dot icon" />
        </IconButton>
        {isShowCollaspe && (
          <IconButton onClick={() => onToggle(id)}>
            {isOpenCollaspe ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        )}
      </Box>
      <DropdownMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        onClickOption={(id) =>
          handleClickOption({
            optionId: id,
            index,
            reasonIndex,
            groupId,
            reasonId,
          })
        }
        menuItems={menuItems}
      />
    </>
  )
}

export default ActionCell
