export const ID = 'Id'
export const USER_ID = 'User ID'
export const USERNAME = 'Username'
export const STATUS = 'Status'
export const ACCOUNT_TYPE = 'Account type'
export const REPORT_HEALTH = 'Report health'
export const FOLLOWERS = 'Followers'
export const LOCKED_AT = 'Locked at'
export const REPORTS_GOT = 'Reports got'
export const DELETED_AT = 'Deleted at'
export const DELETED_BY = 'Deleted by'
export const REPORT_ID = 'Report ID'
export const FROM_USER = 'From user'
export const RECEIVED_AT = 'Received at'
export const ASSIGNED_TO = 'Assigned to'
export const REASON_AND_DETAILS = 'Reason & details'
export const TOP = 'Top'
export const CATEGORY = 'Category'
export const TOTAL_REPORTS = 'Total reports'
export const LOCK_TYPE = 'Lock type'
export const TYPE = 'Type'
export const OWNER_USER = 'Owner/user'
export const NUMBER_OF_REPORTS = 'Number of reports'
export const REPORT_TOPICS = 'Report topics'
export const NOTIFY_ID = 'Notify ID'
export const SENT_AT = 'Sent at'
export const SEND_AT = 'Send at'
export const CREATED_BY = 'Created by'
export const CONTENT = 'Content'
export const SEND_TO = 'Send to'
export const SENT = 'Sent'
export const SCHEDULED_AT = 'Scheduled at'
