import { types } from './constants'

export function turnOffNotificationRequest(data, callback) {
  return {
    type: types.TURN_OFF_NOTIFICATION,
    payload: { data, callback },
  }
}

export function turnOffNotificationSuccess(data) {
  return {
    type: types.TURN_OFF_NOTIFICATION_SUCCESS,
    payload: { data },
  }
}

export function turnOffNotificationFail(error) {
  return {
    type: types.TURN_OFF_NOTIFICATION_FAILED,
    payload: { error },
  }
}

export function fetchMainteanceMode() {
  return {
    type: types.FETCH_MAINTENANCE_MODE,
  }
}

export function fetchMainteanceModeSuccess(payload) {
  return {
    type: types.FETCH_MAINTENANCE_MODE_SUCCESS,
    payload,
  }
}

export function getReportsSettingRequest(callback) {
  return {
    type: types.GET_REPORTS_SETTING,
    payload: { callback },
  }
}

export function getReportsSettingSuccess(data) {
  return {
    type: types.GET_REPORTS_SETTING_SUCCESS,
    payload: { data },
  }
}

export function getReportsSettingFail(error) {
  return {
    type: types.GET_REPORTS_SETTING_FAILED,
    payload: { error },
  }
}

export function saveReportsSettingRequest(data, callback) {
  return {
    type: types.SAVE_REPORTS_SETTING,
    payload: { data, callback },
  }
}

export function saveReportsSettingSuccess(data) {
  return {
    type: types.SAVE_REPORTS_SETTING_SUCCESS,
    payload: { data },
  }
}

export function saveReportsSettingFail(error) {
  return {
    type: types.SAVE_REPORTS_SETTING_FAILED,
    payload: { error },
  }
}

export function getAutoLockSettingRequest(callback) {
  return {
    type: types.GET_AUTO_LOCK_SETTING,
    payload: { callback },
  }
}

export function getAutoLockSettingSuccess(data) {
  return {
    type: types.GET_AUTO_LOCK_SETTING_SUCCESS,
    payload: { data },
  }
}

export function getAutoLockSettingFail(error) {
  return {
    type: types.GET_AUTO_LOCK_SETTING_FAILED,
    payload: { error },
  }
}

export function saveAutoLockSettingRequest(data, callback) {
  return {
    type: types.SAVE_AUTO_LOCK_SETTING,
    payload: { data, callback },
  }
}

export function saveAutoLockSettingSuccess(data) {
  return {
    type: types.SAVE_AUTO_LOCK_SETTING_SUCCESS,
    payload: { data },
  }
}

export function saveAutoLockSettingFail(error) {
  return {
    type: types.SAVE_AUTO_LOCK_SETTING_FAILED,
    payload: { error },
  }
}

export function getSubpageSettingRequest(callback) {
  return {
    type: types.GET_SUB_PAGE_SETTING,
    payload: { callback },
  }
}

export function getSubpageSettingSuccess(data) {
  return {
    type: types.GET_SUB_PAGE_SETTING_SUCCESS,
    payload: { data },
  }
}

export function getSubpageSettingFail(error) {
  return {
    type: types.GET_SUB_PAGE_SETTING_FAILED,
    payload: { error },
  }
}

export function saveSubpageSettingRequest(data, pageName, callback) {
  return {
    type: types.SAVE_SUB_PAGE_SETTING,
    payload: { data, pageName, callback },
  }
}

export function saveSubpageSettingSuccess(data) {
  return {
    type: types.SAVE_SUB_PAGE_SETTING_SUCCESS,
    payload: { data },
  }
}

export function saveSubpageSettingFail(error) {
  return {
    type: types.SAVE_SUB_PAGE_SETTING_FAILED,
    payload: { error },
  }
}
