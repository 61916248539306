import Dashboard from 'pages/Dashboard'
import Users from 'pages/Users'
import DetailUser from 'pages/Users/DetailUser'
import MyAccount from 'pages/MyAccount'
import Reports from 'pages/Reports'
import DetailReport from 'pages/Reports/DetailReport'
import UpdateInfo from 'pages/MyAccount/UpdateInfo'
import Contents from 'pages/Contents'
import DetailContent from 'pages/Contents/DetailContent'
import Settings from 'pages/Settings'
import Notify from 'pages/Notify'
import DetailNotify from 'pages/Notify/DetailNotify'
import ReportsSetting from 'containers/Settings/Reports'
import SubpagesSetting from 'containers/Settings/Subpages'
import UserApp from 'containers/Settings/UserApp'

const routes = [
  {
    path: '/',
    title: 'Dashboard',
    element: Dashboard,
    isShowDatePicker: true,
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    element: Dashboard,
    isShowDatePicker: true,
  },
  {
    path: '/users',
    title: 'Users',
    element: Users,
  },
  {
    path: '/users/:id',
    title: 'User',
    subTitle: 'User Detail',
    element: DetailUser,
  },
  {
    path: '/my-account',
    title: 'My Account',
    element: MyAccount,
  },
  {
    path: '/my-account/update',
    title: 'My Account',
    element: UpdateInfo,
  },
  {
    path: '/reports',
    title: 'Reports',
    element: Reports,
  },
  {
    path: '/reports/:id',
    title: 'Report',
    subTitle: 'Report Detail',
    element: DetailReport,
  },
  {
    path: '/contents/:type',
    title: 'Contents',
    element: Contents,
    isShowDatePicker: true,
  },
  {
    path: '/contents/:type/:id',
    title: 'Contents',
    element: DetailContent,
    subTitle: 'Content Detail',
  },
  {
    path: '/notify/:type',
    title: 'Push Notify',
    element: Notify,
    isShowDatePicker: true,
  },
  {
    path: '/notify/:type/:id',
    title: 'Notify',
    element: DetailNotify,
    subTitle: 'Notification details',
  },
  {
    path: '/settings',
    title: 'Settings',
    element: Settings,
    children: [
      {
        path: 'report',
        title: 'Settings',
        subTitle: 'Reports',
        element: ReportsSetting,
      },
      {
        path: 'subpages/:tab',
        title: 'Settings',
        subTitle: 'Sub-pages',
        element: SubpagesSetting,
      },
      {
        path: 'user-app',
        title: 'Settings',
        subTitle: 'On/off User App',
        element: UserApp,
      },
    ],
  },
]

export default routes
