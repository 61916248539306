import * as React from 'react'
import { StyledTab, StyledTabs } from './styles'

export default function TableTab({
  tabs = [],
  currentTab,
  onChange = () => {},
}) {
  return (
    <StyledTabs value={currentTab} onChange={onChange} aria-label="tabs">
      {tabs.map((tab) => (
        <StyledTab key={tab.id} label={tab.label} />
      ))}
    </StyledTabs>
  )
}
