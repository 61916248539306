import axiosClient from 'utils/axios'

const userBaseUrl = '/users'

export const getAdminInfo = () => {
  return axiosClient.get(`${userBaseUrl}/me`)
}
export const updateAdminInfo = (data) => {
  return axiosClient.put(`${userBaseUrl}/me`, data)
}
export const changePassword = (data) => {
  return axiosClient.post('auth/password/change', data)
}

export const uploadImage = (data) => {
  const formData = new FormData()
  formData.append('files', data)
  formData.append('shouldKeepOriginal', 'true')
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axiosClient.post(`file-upload`, formData, config)
}
