export const types = {
  GET_MY_ACCOUNT_REQUEST: 'GET_MY_ACCOUNT_REQUEST',
  GET_MY_ACCOUNT_SUCCESS: 'GET_MY_ACCOUNT_SUCCESS',
  GET_MY_ACCOUNT_FAIL: 'GET_MY_ACCOUNT_FAIL',

  UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAIL: 'UPDATE_ACCOUNT_FAIL',

  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAIL: 'UPLOAD_IMAGE_FAIL',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
}
