export const DESC_SORT = 'DESC'
export const ASC_SORT = 'ASC'

export const DEFAULT_LIMIT = 10
export const DEFAULT_PAGE = 1
export const DEFAULT_SORT_DIRECTION = DESC_SORT
export const LOCKED_STATUS = 'locked'

export const PRICE_UNIT = {
  usd: '$',
  eur: '€',
  gbp: '£',
  jpy: '¥',
  cny: '¥',
  aud: '$',
  cad: '$',
  krw: '₩',
  inr: '₹',
}

export const PRICE_TYPES = {
  per_hour: 'Hour',
  free: 'Free',
  per_ticket: 'Ticket',
  normal: '',
}

export const PATH_PREFIX = {
  post: 'post',
  events: 'events',
  tools: 'tools',
  services: 'services',
  plants: 'plants',
  profile: 'profile',
}

export const REPORT_TYPES_LABEL = {
  user: 'User issues',
  content: 'Content issues',
  review: 'Review issues',
  report: 'Report issues',
}

export const DEFAULT_FROM_DATE_ALL_TIME = '02-01-2024'
export const DEFAULT_FROM_DATE_ALL_TIME_PROD = '07-03-2024'
