export const types = {
  FETCH_STATISTIC_REPORT_REQUEST: 'FETCH_STATISTIC_REPORT_REQUEST',
  FETCH_STATISTIC_REPORT_SUCCESS: 'FETCH_STATISTIC_REPORT_SUCCESS',
  FETCH_STATISTIC_REPORT_FAIL: 'FETCH_STATISTIC_REPORT_FAIL',

  FETCH_STATISTIC_AUTOLOCK_REQUEST: 'FETCH_STATISTIC_AUTOLOCK_REQUEST',
  FETCH_STATISTIC_AUTOLOCK_SUCCESS: 'FETCH_STATISTIC_AUTOLOCK_SUCCESS',
  FETCH_STATISTIC_AUTOLOCK_FAIL: 'FETCH_STATISTIC_AUTOLOCK_FAIL',

  FETCH_METRICS_OVERVIEW_REQUEST: 'FETCH_METRICS_OVERVIEW_REQUEST',
  FETCH_METRICS_OVERVIEW_SUCCESS: 'FETCH_METRICS_OVERVIEW_SUCCESS',
  FETCH_METRICS_OVERVIEW_FAIL: 'FETCH_METRICS_OVERVIEW_FAIL',

  FETCH_USERS_ACTIVE_REQUEST: 'FETCH_USERS_ACTIVE_REQUEST',
  FETCH_USERS_ACTIVE_SUCCESS: 'FETCH_USERS_ACTIVE_SUCCESS',
  FETCH_USERS_ACTIVE_FAIL: 'FETCH_USERS_ACTIVE_FAIL',

  FETCH_USERS_AVERAGE_REQUEST: 'FETCH_USERS_AVERAGE_REQUEST',
  FETCH_USERS_AVERAGE_SUCCESS: 'FETCH_USERS_AVERAGE_SUCCESS',
  FETCH_USERS_AVERAGE_FAIL: 'FETCH_USERS_AVERAGE_FAIL',
}
