import { types } from './constants'

// {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListContents = (payload) => ({
  type: types.GET_LIST_CONTENTS_REQUEST,
  payload,
})

export const getListContentsSuccess = (payload) => ({
  type: types.GET_LIST_CONTENTS_SUCCESS,
  payload,
})

export const getListContentsFailed = (payload) => ({
  type: types.GET_LIST_CONTENTS_FAILED,
  payload,
})

export const getContentsOverview = () => ({
  type: types.GET_CONTENTS_OVERVIEW_REQUEST,
})

export const getContentsOverviewSuccess = (payload) => ({
  type: types.GET_CONTENTS_OVERVIEW_SUCCESS,
  payload,
})

export const getContentsOverviewFailed = (payload) => ({
  type: types.GET_CONTENTS_OVERVIEW_FAILED,
  payload,
})

export const getDetailContent = (id, type, callback) => ({
  type: types.GET_DETAIL_CONTENT_REQUEST,
  payload: { id, type, callback },
})

export const getDetailContentSuccess = (payload) => ({
  type: types.GET_DETAIL_CONTENT_SUCCESS,
  payload,
})

export const getDetailContentFailed = (payload) => ({
  type: types.GET_DETAIL_CONTENT_FAILED,
  payload,
})

export const getDetailContentReports = (params, type) => ({
  type: types.GET_DETAIL_CONTENT_REPORTS_REQUEST,
  payload: { type, params },
})

export const getDetailContentReportsSuccess = (payload) => ({
  type: types.GET_DETAIL_CONTENT_REPORTS_SUCCESS,
  payload,
})

export const getDetailContentReportsFailed = (payload) => ({
  type: types.GET_DETAIL_CONTENT_REPORTS_FAILED,
  payload,
})

export const getDetailContentAttendEvent = (id, params, callback) => ({
  type: types.GET_DETAIL_CONTENT_ATTEND_EVENT_REQUEST,
  payload: { id, params, callback },
})

export const getDetailContentAttendEventSuccess = (payload) => ({
  type: types.GET_DETAIL_CONTENT_ATTEND_EVENT_SUCCESS,
  payload,
})

export const getDetailContentAttendEventFailed = (payload) => ({
  type: types.GET_DETAIL_CONTENT_ATTEND_EVENT_FAILED,
  payload,
})

export const getContentReactions = (id, callback) => ({
  type: types.GET_CONTENT_REACTIONS_REQUEST,
  payload: { id, callback },
})

export const getContentReactionsSuccess = (payload) => ({
  type: types.GET_CONTENT_REACTIONS_SUCCESS,
  payload,
})

export const getContentReactionsFailed = (payload) => ({
  type: types.GET_CONTENT_REACTIONS_FAILED,
  payload,
})

export const getContentComments = (id, callback) => ({
  type: types.GET_CONTENT_COMMENTS_REQUEST,
  payload: { id, callback },
})

export const getContentCommentsSuccess = (payload) => ({
  type: types.GET_CONTENT_COMMENTS_SUCCESS,
  payload,
})

export const getContentCommentsFailed = (payload) => ({
  type: types.GET_CONTENT_COMMENTS_FAILED,
  payload,
})

export const deleteComment = (id, callback) => ({
  type: types.DELETE_CONTENT_COMMENT,
  payload: { id, callback },
})

export const deleteContent = (id, callback) => ({
  type: types.DELETE_CONTENT,
  payload: { id, callback },
})
