import { buildUrlWithQueryStrings } from 'utils'
import axiosClient from 'utils/axios'

const authBaseUrl = '/admin/posts'
// params = {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListContents = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings(authBaseUrl, params))
}

export const getListSocialContents = (params) => {
  return axiosClient.get(buildUrlWithQueryStrings('/contents', params))
}

export const getContentsOverview = (params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`${authBaseUrl}/overview-statistic`, params)
  )
}

export const getDetailContent = (id) => {
  return axiosClient.get(`${authBaseUrl}/${id}`)
}

export const getDetailSocialContent = (id) => {
  return axiosClient.get(`/contents/${id}`)
}

export const getReports = (params, type = '') => {
  if (type === 'social') {
    return axiosClient.get(
      buildUrlWithQueryStrings(`/contents/${params.post_id}/reports`, {
        limit: params.limit,
        page: params.page,
      })
    )
  }
  return axiosClient.get(buildUrlWithQueryStrings('/reports', params))
}

export const getContentReactions = (id) => {
  return axiosClient.get(`/contents/${id}/reactions`)
}

export const getContentComments = (id) => {
  return axiosClient.get(`/comments?content_id=${id}`)
}

export const getContentCommentReplies = (id) => {
  return axiosClient.get(`/comments/${id}/replies`)
}

export const deleteComment = (id) => {
  return axiosClient.delete(`/comments/${id}`)
}

export const deleteContent = (id) => {
  return axiosClient.delete(`/contents/${id}`)
}

export const getPlanningToAttend = (id, params) => {
  return axiosClient.get(
    buildUrlWithQueryStrings(`posts/${id}/planning-to-attend-event`, params)
  )
}
