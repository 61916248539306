import Button from '@mui/material/Button'
import { styled } from '@mui/system'

const ContainedButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.5,
  backgroundColor: '#8EF351',
  borderColor: '#0063cc',
  borderRadius: 16,
  height: 48,
}))

export default ContainedButton
