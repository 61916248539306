import axiosClient from 'utils/axios'

const authBaseUrl = '/auth'

export const loginApi = (data) => {
  return axiosClient.post(`${authBaseUrl}/login`, data)
}

export const forgotPasswordApi = (data) => {
  return axiosClient.post(`${authBaseUrl}/forget-password`, data)
}

export const resetPassword = (token, data) => {
  return axiosClient.post(`${authBaseUrl}/reset-password`, data)
}

export const getCategories = () => {
  return axiosClient.get('/categories')
}

export const formatCategories = (categories) => {
  const category = {}

  categories.forEach((item) => {
    category[item.key] = { id: item.id, name: item.name }
  })
  return category
}
