import { produce } from 'immer'
import dayjs from 'dayjs'
import { types } from './constants'
import {
  DEFAULT_FROM_DATE_ALL_TIME,
  DEFAULT_FROM_DATE_ALL_TIME_PROD,
} from 'constants/common'

export const initialState = {
  setting: {
    date: dayjs(
      process.env.ENV === 'PROD'
        ? DEFAULT_FROM_DATE_ALL_TIME_PROD
        : DEFAULT_FROM_DATE_ALL_TIME
    ).toISOString(),
    endDate: dayjs().toISOString(),
  },
}

const cacheReducers = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.CHANGE_DATE_PICKER:
        draft.setting.date = payload
        break
      case types.CHANGE_END_DATE_PICKER:
        draft.setting.endDate = payload
        break
      case types.CHANGE_DATE_TO_ALL_TIME:
        draft.setting.date = payload.from
        draft.setting.endDate = payload.to
        break
      default:
        break
    }
  })

export default cacheReducers
