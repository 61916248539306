import { createSelector } from 'reselect'
import { get } from 'lodash'

const cacheState = (state) => get(state, 'cache')

const getDatePickerState = () =>
  createSelector(cacheState, (cache) => cache.setting.date)

const getEndDatePickerState = () =>
  createSelector(cacheState, (cache) => cache.setting.endDate)

export { getDatePickerState, getEndDatePickerState }
