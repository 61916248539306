import { produce } from 'immer'

import { types } from './constants'

export const initialState = {
  statistic: {
    available_users: 0,
    available_companies: 0,
    auto_locked: 0,
    isLoading: false,
  },
  lists: { total: 0, rows: [], isSuccess: true, isLoading: false },
  detail: {
    data: {},
    isLoading: false,
    posts: {
      records: [],
    },
    table: {
      records: [],
    },
    reports: {
      records: [],
      summary: {
        new: 0,
        completed: 0,
        'in-progress': 0,
        rejected: 0,
        totalReport: 0,
      },
      isLoadingReports: false,
    },
    total: 0,
    isLoadingTable: false,
  },
}

const usersReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.GET_LIST_USERS_REQUEST:
        draft.lists.isLoading = true
        break
      case types.GET_USER_STATISTIC_REQUEST:
        draft.statistic.isLoading = true
        break
      case types.GET_LIST_USERS_SUCCESS:
        const { data } = payload
        draft.lists.isLoading = false
        draft.lists.rows = data.records
        draft.lists.total = data.total
        break
      case types.GET_LIST_USERS_FAILED:
        draft.lists.isLoading = false
        break
      case types.GET_USER_STATISTIC_SUCCESS:
        draft.statistic.isLoading = false
        draft.statistic.available_users = payload.data.available_users
        draft.statistic.available_companies = payload.data.available_companies
        draft.statistic.auto_locked = payload.data.auto_locked
        break
      case types.GET_DETAIL_USER_REQUEST:
        draft.detail.isLoading = true
        break
      case types.GET_DETAIL_USER_SUCCESS:
        draft.detail.isLoading = false
        draft.detail.data = payload.data
        break
      case types.GET_DETAIL_USER_FAILED:
        draft.detail.isLoading = false
        break
      case types.GET_POSTS_DATA_REQUEST:
        draft.detail.isLoadingTable = true
        draft.detail.posts.records = []
        draft.detail.total = 0
        break
      case types.GET_POSTS_DATA_SUCCESS:
        draft.detail.isLoadingTable = false
        draft.detail.posts.records = payload.data.records
        draft.detail.total = payload.data.total
        break
      case types.GET_POSTS_DATA_FAILED:
        draft.detail.isLoadingTable = false
        draft.detail.posts.records = []
        break
      case types.GET_TABLE_DATA_REQUEST:
        draft.detail.isLoadingTable = true
        draft.detail.table.records = []
        draft.detail.total = 0
        break
      case types.GET_TABLE_DATA_SUCCESS:
        draft.detail.isLoadingTable = false
        draft.detail.table.records = payload.data.records
        draft.detail.total = payload.data.total
        break
      case types.GET_TABLE_DATA_FAILED:
        draft.detail.isLoadingTable = false
        draft.detail.table.records = []
        break
      case types.GET_REPORTS_REQUEST:
        draft.detail.reports.isLoadingReports = true
        draft.detail.reports.records = []
        draft.detail.total = 0
        break
      case types.GET_REPORTS_SUCCESS:
        draft.detail.reports.isLoadingReports = false
        draft.detail.reports.records = payload.data.records
        draft.detail.total = payload.data.total
        break
      case types.GET_REPORTS_FAILED:
        draft.detail.reports.isLoadingReports = false
        draft.detail.reports.records = []
        break
      case types.GET_SUMMARY_REPORTS_SUCCESS:
        const totalNew = (payload.data.total_new ??= 0)
        const totalCompleted = (payload.data.total_completed ??= 0)
        const totalInProgress = (payload.data.total_inprogress ??= 0)
        const totalRejected = (payload.data.total_rejected ??= 0)
        draft.detail.reports.summary.new = totalNew
        draft.detail.reports.summary.completed = totalCompleted
        draft.detail.reports.summary['in-progress'] = totalInProgress
        draft.detail.reports.summary.rejected = totalRejected
        draft.detail.reports.summary.totalReport =
          totalNew + totalRejected + totalCompleted + totalInProgress
        break
      default:
        break
    }
  })

export default usersReducer
