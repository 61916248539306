import { takeLatest, call, put, fork } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'
import { showSnackbar } from 'containers/Snackbar/store/actions'

function* fetchMainteanceMode() {
  try {
    const {
      data: { data },
    } = yield call(services.fetchMaintenanceMode)
    yield put(
      actions.fetchMainteanceModeSuccess(!!data?.maintenance_mode?.enabled)
    )
  } catch (error) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Fetch maintenance mode failed',
      })
    )
  }
}

function* turnOffNotification(action) {
  const { data, callback } = action.payload
  try {
    yield call(services.turnOffNotification, data)
    yield put(actions.turnOffNotificationSuccess())
    callback && callback()
    yield fork(fetchMainteanceMode)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Success',
      })
    )
  } catch (error) {
    yield put(actions.turnOffNotificationFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Failed',
      })
    )
  }
}

function* getReportsSetting(action) {
  const { callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getReportsSetting)
    callback && callback(data)
    yield put(actions.getReportsSettingSuccess(data))
  } catch (error) {
    yield put(actions.getReportsSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Get report setting failed',
      })
    )
  }
}

function* saveReportsSetting(action) {
  const { data: submitData, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.saveReportsSetting, submitData)
    callback && callback(data)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Save report success',
      })
    )
    yield put(actions.saveReportsSettingSuccess(data))
  } catch (error) {
    yield put(actions.saveReportsSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Save report setting failed',
      })
    )
  }
}

function* getAutoLockSetting(action) {
  const { callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getAutoLockSetting)
    callback && callback(data)
    yield put(actions.getAutoLockSettingSuccess(data))
  } catch (error) {
    yield put(actions.getAutoLockSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Get auto lock setting failed',
      })
    )
  }
}

function* saveAutoLockSetting(action) {
  const { data: submitData, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.saveAutoLockSetting, submitData)
    callback && callback(data)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Save auto lock success',
      })
    )
    yield put(actions.saveAutoLockSettingSuccess(data))
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Update success',
      })
    )
  } catch (error) {
    yield put(actions.saveAutoLockSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Save autolock setting failed',
      })
    )
  }
}

function* getSubpageSetting(action) {
  const { callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getSubpageSetting)
    const formatData = data.reduce(
      (result, obj) => ({ ...result, [obj.page_name]: obj.content }),
      {}
    )
    callback && callback(formatData)
    yield put(actions.getSubpageSettingSuccess(data))
  } catch (error) {
    yield put(actions.getSubpageSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Get auto lock setting failed',
      })
    )
  }
}

function* saveSubpageSetting(action) {
  const { data: submitData, pageName, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.saveSubpageSetting, submitData, pageName)
    callback && callback(data)
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Save sub pages content success',
      })
    )
    yield put(actions.saveSubpageSettingSuccess(data))
  } catch (error) {
    yield put(actions.saveSubpageSettingFail())
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Save Subpage setting failed',
      })
    )
  }
}

export default function* settingsWatcher() {
  yield takeLatest(types.TURN_OFF_NOTIFICATION, turnOffNotification)
  yield takeLatest(types.FETCH_MAINTENANCE_MODE, fetchMainteanceMode)
  yield takeLatest(types.GET_REPORTS_SETTING, getReportsSetting)
  yield takeLatest(types.SAVE_REPORTS_SETTING, saveReportsSetting)
  yield takeLatest(types.GET_AUTO_LOCK_SETTING, getAutoLockSetting)
  yield takeLatest(types.SAVE_AUTO_LOCK_SETTING, saveAutoLockSetting)
  yield takeLatest(types.GET_SUB_PAGE_SETTING, getSubpageSetting)
  yield takeLatest(types.SAVE_SUB_PAGE_SETTING, saveSubpageSetting)
}
