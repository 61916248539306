import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'

const StyledBtn = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'isEnable',
})(({ isEnable = false, theme }) => ({
  textTransform: 'none',
  borderRadius: 8,
  color: theme.palette.main[500],
  backgroundColor: isEnable
    ? theme.palette.main[600]
    : theme.palette.basic[700],
  fontSize: 14,
  '&:hover': {
    backgroundColor: isEnable
      ? theme.palette.primary.main
      : theme.palette.basic[700],
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.basic[700],
  },
}))

export default function FilledButton({
  onClick,
  label = '',
  isEnable = false,
  ...rest
}) {
  return (
    <StyledBtn
      {...rest}
      onClick={onClick}
      isEnable={isEnable}
      sx={{ mr: 2, paddingX: 3 }}
    >
      {label}
    </StyledBtn>
  )
}
