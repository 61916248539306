import { combineReducers } from 'redux'
import snackbar from 'containers/Snackbar/store/reducers'
import loginReducer from 'containers/auth/store/reducers'
import usersReducer from 'containers/Users/store/reducers'
import dashboardReducers from 'containers/Dashboard/store/reducers'
import cacheReducer from 'containers/cache/store/reducers'
import myAccountReducer from 'containers/MyAccount/store/reducers'
import reportsReducer from 'containers/Reports/store/reducers'
import contentsReducer from 'containers/Contents/store/reducers'
import settingsReducer from 'containers/Settings/store/reducers'
import notifyReducer from 'containers/Notify/store/reducers'

export const rootReducer = combineReducers({
  auth: loginReducer,
  snackbar,
  dashboard: dashboardReducers,
  users: usersReducer,
  myAccount: myAccountReducer,
  reports: reportsReducer,
  contents: contentsReducer,
  settings: settingsReducer,
  notify: notifyReducer,
  cache: cacheReducer,
})
