import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'

const DatePicker = styled(MuiDatePicker)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    fontSize: 14,
  },
  '& .MuiInputBase-input': {
    fontSize: 14,
    borderColor: theme.palette.basic[700],
    borderRadius: 8,
  },
  '& .MuiOutlinedInput-input': {
    fontSize: 14,
    borderColor: theme.palette.basic[700],
    padding: '6px 14px',
    height: 'fit-content',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.basic[700],
  },
  '& .MuiIconButton-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
}))

export default function BasicDatePicker({
  onChangeStartDate,
  onChangeEndDate,
  from,
  to,
  ...rest
}) {
  const today = dayjs()
  const fromDate = dayjs(from)
  const toDate = dayjs(to)
  const handleStartDateChange = (startDate) => {
    if (startDate && toDate && startDate.isAfter(toDate, 'day')) {
      onChangeStartDate(startDate)
      onChangeEndDate(startDate)
    }
    if (onChangeStartDate) onChangeStartDate(startDate)
  }

  const handleEndDateChange = (endDate) => {
    if (endDate && fromDate && endDate.isBefore(fromDate, 'day')) {
      onChangeStartDate(endDate)
      onChangeEndDate(endDate)
    }
    if (onChangeEndDate) onChangeEndDate(endDate)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <DatePicker
          label="From"
          maxDate={today}
          value={fromDate}
          onChange={handleStartDateChange}
          renderInput={(props) => <TextField {...props} />}
        />
        <DatePicker
          label="To"
          maxDate={today}
          value={toDate}
          onChange={handleEndDateChange}
          renderInput={(props) => <TextField {...props} />}
        />
      </Box>
    </LocalizationProvider>
  )
}
