import { produce } from 'immer'
import { types } from './constants'

export const initialState = {
  isRequesting: false,
  dataStatisticReport: [],
  dataStatisticAutoLock: [],
  dataMetricsOverview: [],
  dataUserActive: {
    details: [],
    total: 0,
  },
  dataUserAverage: [],
}

const dashboardReducers = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_STATISTIC_REPORT_REQUEST:
      case types.FETCH_STATISTIC_AUTOLOCK_REQUEST:
      case types.FETCH_METRICS_OVERVIEW_REQUEST:
      case types.FETCH_USERS_ACTIVE_REQUEST:
      case types.FETCH_USERS_AVERAGE_REQUEST:
        draft.isRequesting = true
        break
      case types.FETCH_STATISTIC_REPORT_SUCCESS:
        draft.isRequesting = false
        draft.dataStatisticReport = action.payload
        break
      case types.FETCH_STATISTIC_AUTOLOCK_SUCCESS:
        draft.isRequesting = false
        draft.dataStatisticAutoLock = action.payload
        break
      case types.FETCH_METRICS_OVERVIEW_SUCCESS:
        draft.isRequesting = false
        draft.dataMetricsOverview = action.payload
        break
      case types.FETCH_USERS_ACTIVE_SUCCESS:
        draft.isRequesting = false
        draft.dataUserActive = action.payload
        break
      case types.FETCH_USERS_AVERAGE_SUCCESS:
        draft.isRequesting = false
        draft.dataUserAverage = action.payload
        break
      case types.FETCH_STATISTIC_REPORT_FAIL:
      case types.FETCH_METRICS_OVERVIEW_FAIL:
      case types.FETCH_USERS_ACTIVE_FAIL:
      case types.FETCH_USERS_AVERAGE_FAIL:
      case types.FETCH_STATISTIC_AUTOLOCK_FAIL:
        draft.isRequesting = false
        break
      default:
        break
    }
  })

export default dashboardReducers
