import deleteIcon from 'assets/images/delete.svg'
import editIcon from 'assets/images/edit-2.svg'

export const TABS = [
  {
    value: '/settings/report',
    label: 'Reports',
  },
  {
    value: '/settings/subpages/about_us',
    label: 'Sub-pages',
  },
  {
    value: '/settings/user-app',
    label: 'On/Off User App',
  },
]

export const TABS_REPORTS = [
  {
    id: 'report',
    label: 'Reports',
  },
  {
    id: 'auto-lock',
    label: 'Auto lock',
  },
]

export const DELETE_MENU = {
  id: 'delete',
  title: 'Delete',
  icon: <img src={deleteIcon} alt="deleteIcon" />,
  color: '#F5222D',
}

export const RENAME_GROUP = {
  id: 'rename-group',
  title: 'Re-name group',
  icon: <img src={editIcon} alt="editIcon" />,
  color: '#FFFFFF',
}

export const RENAME_SUB = {
  id: 'rename-sub',
  title: 'Re-name sub',
  icon: <img src={editIcon} alt="editIcon" />,
  color: '#FFFFFF',
}

export const SUBPAGE_TABS = [
  {
    value: 'about_us',
    label: 'About us',
  },
  {
    value: 'term_of_use',
    label: 'Term of use',
  },
  {
    value: 'privacy',
    label: 'Privacy',
  },
  {
    value: 'faqs',
    label: 'FAQs',
  },
]

export const TITLE_MAPPING = {
  about_us: 'About us',
  term_of_use: 'Term of use',
  privacy: 'Privacy',
  faqs: 'FAQs',
}

export const LINK_MAPPING = {
  about_us: 'about-us',
  term_of_use: 'docs/terms_of_use',
  privacy: 'docs/privacy_and_policy/',
  faqs: 'docs/faqs',
}
