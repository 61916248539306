import { types } from './constants'

export const changeDatePicker = (payload) => ({
  type: types.CHANGE_DATE_PICKER,
  payload,
})

export const changeEndDatePicker = (payload) => ({
  type: types.CHANGE_END_DATE_PICKER,
  payload,
})

export const changeDateToAllTime = ({ from, to }) => ({
  type: types.CHANGE_DATE_TO_ALL_TIME,
  payload: { from, to },
})
