import { styled } from '@mui/material/styles'
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material'

export const StyledTabs = styled((props) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTab-root': {
    backgroundColor: theme.palette.background.default,
    width: 138,
    borderRadius: '8px 8px 0 0',
    color: '#fff',
    '&.Mui-selected': {
      color: '#8EF351',
      backgroundImage: 'linear-gradient(to bottom, #16230E, #0E111E)',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 1,
    borderRadius: '5px 5px 0 0 ',
    top: 0,
  },
  '& .MuiTabs-indicatorSpan': {
    width: 'calc(100% - 16px)',
    backgroundColor: '#8EF351',
  },
}))

export const StyledTab = styled((props) => <MuiTab {...props} />)(() => ({
  textTransform: 'none',
  marginRight: 4,
}))
