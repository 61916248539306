import React, { useEffect, useState } from 'react'
import { Box, Grid, Button, useTheme, CircularProgress } from '@mui/material'
import logoApp from 'assets/images/logo_app.svg'
import flashSlash from 'assets/images/flash-slash.svg'
import flashSlashYellow from 'assets/images/flash-slash-yellow.svg'
import falshTurnOn from 'assets/images/flash_turn_on.svg'
import falshTurnOnYellow from 'assets/images/flash-turn-on-yellow.svg'
import warningIcon from 'assets/images/warning-3.svg'
import { getSettingsState } from './store/selectors'
import { useSelector } from 'react-redux'
import Popup from 'components/Popup'
import { useFormik } from 'formik'
import * as yup from 'yup'
import PasswordInput from 'components/Input/PasswordInput'
import {
  fetchMainteanceMode,
  turnOffNotificationRequest,
} from './store/actions'
import { useDispatch } from 'react-redux'

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .required('New Password is required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm New Password is required'),
})

export const getSwitchModeSteps = (maintainMode) => ({
  1: {
    title: `Turn ${maintainMode ? 'OFF' : 'ON'} maintenance mode`,
    note: '',
  },
  2: {
    title: 'Enter password to continue',
    note: '',
  },
  3: {
    title: `Maintenance mode has been ${maintainMode ? 'ON' : 'OFF'}`,
    note: '',
  },
})

const getSubmitTextByStep = (step, maintainMode) => {
  const SUBMIT_TEXT = {
    1: `Turn ${maintainMode ? 'OFF' : 'ON'}`,
    2: `Confirm ${maintainMode ? 'OFF' : 'ON'} maintenance`,
    3: '',
  }
  return SUBMIT_TEXT[step]
}

const UserApp = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { isRequesting, maintenanceMode, isFetchMode } =
    useSelector(getSettingsState())

  const [openPopup, setOpenPopup] = useState(false)
  const [popupStep, setPopupStep] = useState(1)

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: validationSchema,
  })

  const handleClickSubmitPopup = () => {
    if (!(popupStep === 2)) {
      setPopupStep((prevStep) => prevStep + 1)
    } else {
      if (
        formik.isValid &&
        formik.values.newPassword &&
        formik.values.newPassword === formik.values.confirmNewPassword
      ) {
        dispatch(
          turnOffNotificationRequest(
            {
              maintenance_mode: {
                enabled: !maintenanceMode,
              },
              password: formik.values.newPassword,
            },
            () => {
              setPopupStep(3)
            }
          )
        )
      }
    }
  }

  const handleOpenPopupConfirm = () => setOpenPopup(true)

  useEffect(() => {
    dispatch(fetchMainteanceMode())
  }, [dispatch])

  return (
    <Grid sx={{ p: 3, width: '100%', height: 'calc(100vh - 100px)' }}>
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          borderRadius: '8px',
          p: '20px',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '72px', height: '72px', borderRadius: '8px' }}>
            <Box
              component="img"
              src={logoApp}
              alt="logo app icon"
              sx={{ width: '100%', height: '100%' }}
            />
          </Box>
          <Box sx={{ ml: 3 }}>
            <Box>Turn ON/OFF maintenance mode on mobile app</Box>
            {isFetchMode ? (
              <CircularProgress />
            ) : (
              <Button
                startIcon={
                  maintenanceMode ? (
                    <Box component="img" src={falshTurnOn} alt="flash icon" />
                  ) : (
                    <Box component="img" src={flashSlash} alt="flash icon" />
                  )
                }
                sx={{
                  mt: 2,
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: maintenanceMode
                    ? theme.palette.main[500]
                    : theme.palette.error[500],
                  borderColor: maintenanceMode
                    ? theme.palette.main[500]
                    : theme.palette.error[500],
                  backgroundColor: maintenanceMode
                    ? theme.palette.main[600]
                    : 'none',
                  '&:hover': {
                    borderColor: maintenanceMode
                      ? theme.palette.main[500]
                      : theme.palette.error[500],
                  },
                  fontSize: 14,
                }}
                variant={maintenanceMode ? 'contained' : 'outlined'}
                onClick={handleOpenPopupConfirm}
              >
                Turn {maintenanceMode ? 'OFF' : 'ON'} Maintenance mode
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Popup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false)
          setPopupStep(1)
          formik.values.newPassword = ''
          formik.values.confirmNewPassword = ''
        }}
        onClickSubmit={handleClickSubmitPopup}
        title={getSwitchModeSteps(maintenanceMode)[popupStep].title}
        note={getSwitchModeSteps(maintenanceMode)[popupStep].note}
        submitText={getSubmitTextByStep(popupStep, maintenanceMode)}
        sx={{
          '& .MuiDialogActions-root': {
            padding: popupStep === 2 ? '0 0 20px' : '',
          },
          '& .MuiDialog-paper': {
            width: '600px',
            alignItems: 'center',
          },
        }}
        bodyComponent={
          popupStep === 2 &&
          (isRequesting ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: '80px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                padding: '0 100px',
                '& .MuiOutlinedInput-root': {
                  color: '#fff',
                  '& fieldset': {
                    borderColor: theme.palette.basic[700], // Custom border color
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.basic[700], // Custom hover border color
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.basic[700], // Border color when the input is focused
                  },
                  fontSize: 14,
                },
                '& .MuiSvgIcon-root': {
                  color: '#FFFFFF ',
                },
                '& .MuiInputLabel-root': {
                  color: '#8F9BB3 ',
                },
              }}
            >
              <PasswordInput
                margin="normal"
                required
                fullWidth
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                name="newPassword"
                label="Password"
                placeholder="Enter your password"
                id="newPassword"
                autoComplete="off"
                sx={{
                  position: 'relative',
                  color: '#fff',
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputLabelProps={{
                  required: false,
                }}
              />
              <PasswordInput
                margin="normal"
                required
                fullWidth
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                name="confirmNewPassword"
                label="Confirm password"
                placeholder="Enter confirm password"
                id="confirmNewPassword"
                autoComplete="off"
                sx={{
                  position: 'relative',
                  color: '#fff',
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmNewPassword &
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
                InputLabelProps={{
                  required: false,
                }}
              />
            </Box>
          ))
        }
        btnSubmit={{
          color: theme.palette.yellow[500],
          startIcon:
            popupStep === 2 ? (
              maintenanceMode ? (
                <Box
                  component="img"
                  src={flashSlashYellow}
                  alt="flashSlashYellow icon"
                />
              ) : (
                <Box
                  component="img"
                  src={falshTurnOnYellow}
                  alt="flashSlash icon"
                />
              )
            ) : (
              <Box component="img" src={warningIcon} alt="delete icon" />
            ),
          variant: 'outlined',
        }}
        maxWidthContent={popupStep === 1 ? '500px' : '100%'}
      />
    </Grid>
  )
}

export default UserApp
