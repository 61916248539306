import { produce } from 'immer'

import { types } from './constants'

export const initialState = {
  statistic: {},
  summary: {},
  lists: {},
  detail: {
    data: {},
    isLoading: false,
  },
}

const reportsReducer = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case types.UPDATE_STATUS_REPORTS_REQUEST:
      case types.UPDATE_ACTION_REPORTS_REQUEST:
      case types.GET_LIST_REPORTS_REQUEST:
        draft.lists.isLoading = true
        break
      case types.GET_REPORT_STATISTIC_REQUEST:
      case types.GET_REPORT_SUMMARY_REQUEST:
        draft.lists.isLoading = true
        break
      case types.GET_LIST_REPORTS_SUCCESS:
        const { data } = payload
        draft.lists.isLoading = false
        draft.lists.rows = data.records
        draft.lists.total = data.total
        break
      case types.GET_REPORT_SUMMARY_SUCCESS:
        draft.isRequesting = false
        draft.summary = payload
        break
      case types.GET_REPORT_STATISTIC_SUCCESS:
        draft.isRequesting = false
        draft.statistic = payload
        break
      case types.GET_DETAIL_REPORTS_REQUEST:
        draft.detail.isLoading = true
        break
      case types.GET_DETAIL_REPORTS_SUCCESS:
        draft.detail.isLoading = false
        draft.detail.data = payload.data
        break
      case types.GET_DETAIL_REPORTS_FAILED:
        draft.detail.isLoading = false
        break
      case types.UPDATE_STATUS_REPORTS_SUCCESS:
      case types.UPDATE_STATUS_REPORTS_FAILED:
      case types.UPDATE_ACTION_REPORTS_SUCCESS:
      case types.UPDATE_ACTION_REPORTS_FAILED:
        draft.lists.isLoading = false
        break
      default:
        break
    }
  })

export default reportsReducer
