import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'

import CookieInstance from 'utils/cookie'

const AuthenticatedRoute = () => {
  const isAuthenticated = CookieInstance.checkCookie(
    process.env.REACT_APP_COOKIE_NAME || 'userToken'
  )

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />
}

export default AuthenticatedRoute
