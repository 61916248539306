import { takeLatest, call, put } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'

import { showSnackbar } from 'containers/Snackbar/store/actions'

function* getMyAccountSaga() {
  try {
    const res = yield call(services.getAdminInfo)
    yield put(actions.getMyAccountSuccess(res.data.data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getMyAccountFail(data))
  }
}
function* updateMyAccountSaga(action) {
  const { data: dataSubmit, callback = () => {} } = action.payload

  try {
    const res = yield call(services.updateAdminInfo, dataSubmit)
    yield put(actions.updateAccountSuccess(res.data.data))
    callback()
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.updateAccountFail(data))
  }
}
function* uploadImageSaga(action) {
  const { data: dataSubmit, callback = () => {} } = action.payload

  try {
    const res = yield call(services.uploadImage, dataSubmit)
    yield put(actions.uploadImageSuccess(res.data.data))
    callback(res.data.data)
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.uploadImageFail(data))
  }
}

function* changePasswordSaga(action) {
  const { dataSubmit, callback = () => {} } = action.payload

  try {
    const res = yield call(services.changePassword, dataSubmit)
    yield put(actions.changePasswordSuccess(res.data.data))
    callback()
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.changePasswordFailed(data))
  }
}

export default function* myAccountWatcher() {
  yield takeLatest(types.GET_MY_ACCOUNT_REQUEST, getMyAccountSaga)
  yield takeLatest(types.UPDATE_ACCOUNT_REQUEST, updateMyAccountSaga)
  yield takeLatest(types.UPLOAD_IMAGE_REQUEST, uploadImageSaga)
  yield takeLatest(types.CHANGE_PASSWORD_REQUEST, changePasswordSaga)
}
