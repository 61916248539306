import { types } from './constants'

// {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
export const getListNotify = (payload) => ({
  type: types.GET_LIST_NOTIFY_REQUEST,
  payload,
})

export const getListNotifySuccess = (payload) => ({
  type: types.GET_LIST_NOTIFY_SUCCESS,
  payload,
})

export const getListNotifyFailed = (payload) => ({
  type: types.GET_LIST_NOTIFY_FAILED,
  payload,
})

export const getDetailNotify = (id, callback) => ({
  type: types.GET_DETAIL_NOTIFY_REQUEST,
  payload: { id, callback },
})

export const getDetailNotifySuccess = (payload) => ({
  type: types.GET_DETAIL_NOTIFY_SUCCESS,
  payload,
})

export const getDetailNotifyFailed = (payload) => ({
  type: types.GET_DETAIL_NOTIFY_FAILED,
  payload,
})

export const deleteNotify = (id, callback) => ({
  type: types.DELETE_NOTIFY,
  payload: { id, callback },
})

export const addNewNotify = (data, callback) => ({
  type: types.ADD_NEW_NOTIFY_REQUEST,
  payload: { callback, data },
})

export const addNewNotifySuccess = (payload) => ({
  type: types.ADD_NEW_NOTIFY_SUCCESS,
  payload,
})

export const addNewNotifyFailed = (payload) => ({
  type: types.ADD_NEW_NOTIFY_FAILED,
  payload,
})

export const updateNotify = (id, data, callback) => ({
  type: types.UPDATE_NOTIFY_REQUEST,
  payload: { id, callback, data },
})

export const updateNotifySuccess = (payload) => ({
  type: types.UPDATE_NOTIFY_SUCCESS,
  payload,
})

export const updateNotifyFailed = (payload) => ({
  type: types.UPDATE_NOTIFY_FAILED,
  payload,
})
