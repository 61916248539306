import { produce } from 'immer'
import { types } from './constants'

export const initialState = {
  isRequesting: false,
  dataAdmin: [],
  error: {},
}

const myAccountReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_MY_ACCOUNT_REQUEST:
      case types.UPDATE_ACCOUNT_REQUEST:
      case types.UPLOAD_IMAGE_REQUEST:
      case types.CHANGE_PASSWORD_REQUEST:
        draft.isRequesting = true
        break
      case types.GET_MY_ACCOUNT_SUCCESS:
        draft.isRequesting = false
        draft.dataAdmin = action.payload
        break
      case types.UPLOAD_IMAGE_SUCCESS:
        draft.isRequesting = false
        break
      case types.CHANGE_PASSWORD_SUCCESS:
        draft.isRequesting = false
        break
      case types.GET_MY_ACCOUNT_FAIL:
      case types.UPDATE_ACCOUNT_FAIL:
      case types.UPLOAD_IMAGE_FAIL:
      case types.CHANGE_PASSWORD_FAILED:
        draft.error = action.payload.error
        draft.isRequesting = false
        break
      default:
        break
    }
  })

export default myAccountReducer
