export const types = {
  GET_LIST_REPORTS_REQUEST: 'report/GET_LIST_REPORTS_REQUEST',
  GET_LIST_REPORTS_SUCCESS: 'report/GET_LIST_USERS_SUCCESS',
  GET_LIST_REPORTS_FAILED: 'report/GET_LIST_REPORTS_FAILED',

  GET_REPORT_STATISTIC_REQUEST: 'GET_REPORT_STATISTIC_REQUEST',
  GET_REPORT_STATISTIC_SUCCESS: 'GET_REPORT_STATISTIC_SUCCESS',
  GET_REPORT_STATISTIC_FAIL: 'GET_REPORT_STATISTIC_FAIL',

  GET_REPORT_SUMMARY_REQUEST: 'GET_REPORT_SUMMARY_REQUEST',
  GET_REPORT_SUMMARY_SUCCESS: 'GET_REPORT_SUMMARY_SUCCESS',
  GET_REPORT_SUMMARY_FAIL: 'GET_REPORT_SUMMARY_FAIL',

  GET_DETAIL_REPORTS_REQUEST: 'GET_DETAIL_REPORTS_REQUEST',
  GET_DETAIL_REPORTS_SUCCESS: 'GET_DETAIL_REPORTS_REQUEST_SUCCESS',
  GET_DETAIL_REPORTS_FAILED: 'GET_DETAIL_REPORTS_REQUEST_FAILED',

  UPDATE_STATUS_REPORTS_REQUEST: 'UPDATE_STATUS_REPORTS_REQUEST',
  UPDATE_STATUS_REPORTS_SUCCESS: 'UPDATE_STATUS_REPORTS_SUCCESS',
  UPDATE_STATUS_REPORTS_FAILED: 'UPDATE_STATUS_REPORTS_FAILED',

  UPDATE_ACTION_REPORTS_REQUEST: 'UPDATE_ACTION_REPORTS_REQUEST',
  UPDATE_ACTION_REPORTS_SUCCESS: 'UPDATE_ACTION_REPORTS_SUCCESS',
  UPDATE_ACTION_REPORTS_FAILED: 'UPDATE_ACTION_REPORTS_FAILED',
}
