import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar, Alert as MuiAlert, Box } from '@mui/material'

import { clearSnackbar } from './store/actions'
import { getSnackbarState } from 'containers/Snackbar/store/selectors'

const vertical = 'top'
const horizontal = 'right'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function SnackContainer() {
  const snackbarState = useSelector(getSnackbarState())
  const { show, snackType, snackMessage, isNotClear } = snackbarState

  const dispatch = useDispatch()

  const anonymousFunction = () => {}

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    isNotClear ? anonymousFunction() : dispatch(clearSnackbar())
  }

  return (
    <Snackbar
      open={show}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Box>
        <Alert
          onClose={handleClose}
          severity={snackType}
          variant="filled"
          sx={{
            width: '100%',
            '& :first-letter': { textTransform: 'capitalize' },
          }}
        >
          {snackMessage}
        </Alert>
      </Box>
    </Snackbar>
  )
}

export default SnackContainer
