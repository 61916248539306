import * as React from 'react'
import { Box, Toolbar, useTheme } from '@mui/material'
import { AppBar } from './styles'
import BasicDatePicker from 'components/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeDatePicker,
  changeDateToAllTime,
  changeEndDatePicker,
} from 'containers/cache/store/actions'
import {
  getDatePickerState,
  getEndDatePickerState,
} from 'containers/cache/store/selectors'
import OutlinedWhiteButton from 'components/Button/OutlinedWhiteButton'
import dayjs from 'dayjs'
import {
  DEFAULT_FROM_DATE_ALL_TIME,
  DEFAULT_FROM_DATE_ALL_TIME_PROD,
} from 'constants/common'

const Topbar = ({
  open,
  title = 'Dashboard',
  subTitle = '',
  isShowDatePicker,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const fromDate = useSelector(getDatePickerState())
  const toDate = useSelector(getEndDatePickerState())

  const handleChangeStartDatePicker = (newDate) => {
    dispatch(changeDatePicker(new Date(newDate).toISOString()))
  }

  const handleChangeEndDatePicker = (newDate) => {
    dispatch(changeEndDatePicker(new Date(newDate).toISOString()))
  }

  const handleSetAllTime = () => {
    const today = dayjs().toISOString()
    const fromDay = dayjs(
      process.env.ENV === 'PROD'
        ? DEFAULT_FROM_DATE_ALL_TIME_PROD
        : DEFAULT_FROM_DATE_ALL_TIME
    ).toISOString()
    dispatch(changeDateToAllTime({ from: fromDay, to: today }))
  }

  return (
    <AppBar open={open}>
      <Toolbar
        sx={{
          backgroundColor: theme.palette.background.default,
          borderBottom: '1px solid #2E3A59',
          minHeight: '65px !important',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          component="div"
          sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
        >
          <Box
            fontWeight={700}
            sx={{ color: subTitle ? '#8F9BB3' : '', display: 'flex' }}
          >
            {title} {subTitle && '|'}
            {subTitle && (
              <Box fontWeight={700} sx={{ color: '#fff', pl: 2 }}>
                {subTitle}
              </Box>
            )}
          </Box>
          {isShowDatePicker && (
            <>
              <BasicDatePicker
                onChangeStartDate={handleChangeStartDatePicker}
                onChangeEndDate={handleChangeEndDatePicker}
                from={fromDate}
                to={toDate}
              />
              <OutlinedWhiteButton
                label="All time"
                onClick={handleSetAllTime}
              />
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
