import * as React from 'react'
import { Tabs as MuiTabs, Tab as MuiTab, Box, useTheme } from '@mui/material'
import { a11yProps } from 'utils'

export default function Tabs({ selectedTab, tabs, onChangTab, ...rest }) {
  const theme = useTheme()
  const handleChange = (event, newValue) => {
    onChangTab(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <MuiTabs
        value={selectedTab}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs"
        sx={{
          '& .MuiTabs-indicator': {
            height: '1px',
          },
          borderBottom: `1px solid ${theme.palette.border.main}`,
        }}
        {...rest}
      >
        {tabs.map((tab) => (
          <MuiTab
            key={tab.value}
            value={tab.value}
            label={
              tab.icon ? (
                <Box
                  sx={{
                    color:
                      tab.value === selectedTab
                        ? theme.palette.primary.secondary
                        : '',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {tab.icon} <Box sx={{ pl: 1 }}>{tab.label}</Box>
                </Box>
              ) : (
                tab.label
              )
            }
            sx={{
              textTransform: 'none',
              color: theme.palette.basic[100],
              borderBottomColor: theme.palette.basic[700],
              fontSize: '16px',
            }}
            {...a11yProps(tab.value)}
          />
        ))}
      </MuiTabs>
    </Box>
  )
}
