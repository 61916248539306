import React, { useState } from 'react'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Paper,
  TextField,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  DELETE_MENU,
  RENAME_GROUP,
  RENAME_SUB,
} from 'constants/settings/consts'
import TextButton from 'components/Button/TextButton.jsx'
import ActionCell from './ActionCell'

const ReportSettingsTable = ({
  data,
  onClickOption,
  onChangeCheckBoxValue,
  onChangeInputValue,
  onAddSub,
}) => {
  const [openRows, setOpenRows] = useState({})

  const handleToggle = (id) => {
    setOpenRows((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundImage: 'none',
        maxHeight: 'calc(100vh - 320px)',
        overflowY: 'auto',
      }}
      id="table-report"
    >
      <Table
        stickyHeader
        sx={{ '& .MuiTableCell-root': { borderBottomColor: '#2E3A59' } }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#222B45', width: 25 }}>
              No.
            </TableCell>
            <TableCell sx={{ backgroundColor: '#222B45' }}>
              Group Reason
            </TableCell>
            <TableCell colSpan={3} sx={{ backgroundColor: '#222B45' }}>
              Apply for
            </TableCell>
            <TableCell sx={{ backgroundColor: '#222B45' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell>General report</TableCell>
            <TableCell>Report an user</TableCell>
            <TableCell>Report a content</TableCell>
            <TableCell colSpan={1}></TableCell>
          </TableRow>
          {data.map((group, index) => (
            <React.Fragment key={group.id}>
              <TableRow
                sx={{ '& .Mui-checked': { color: '#8EF351 !important' } }}
              >
                <TableCell sx={{ width: 25 }}>{index + 1}</TableCell>
                <TableCell>
                  {group.isEditting ? (
                    <div>
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                        }}
                      >
                        English
                      </Box>
                      <TextField
                        id="standard-basic"
                        value={group?.group_name}
                        variant="standard"
                        onChange={(event) => {
                          onChangeInputValue({
                            index,
                            reasonIndex: null,
                            value: event.target.value,
                            lang: 'en',
                          })
                        }}
                      />
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                          marginTop: 2,
                        }}
                      >
                        Lithuanian
                      </Box>
                      <TextField
                        id="standard-basic"
                        value={group?.group_name_multiple_lang?.lt}
                        variant="standard"
                        onChange={(event) => {
                          onChangeInputValue({
                            index,
                            reasonIndex: null,
                            value: event.target.value,
                            lang: 'lt',
                          })
                        }}
                      />
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                          marginTop: 2,
                        }}
                      >
                        Polish
                      </Box>
                      <TextField
                        id="standard-basic"
                        value={group?.group_name_multiple_lang?.pl}
                        variant="standard"
                        onChange={(event) => {
                          onChangeInputValue({
                            index,
                            reasonIndex: null,
                            value: event.target.value,
                            lang: 'pl',
                          })
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                        }}
                      >
                        English
                      </Box>
                      <div>
                        {group.group_name ??
                          group?.group_name_multiple_lang?.en}
                      </div>
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                          marginTop: 2,
                        }}
                      >
                        Lithuanian
                      </Box>
                      <div>{group?.group_name_multiple_lang?.lt}</div>
                      <Box
                        sx={{
                          color: '#8F9BB3',
                          fontSize: 12,
                          marginTop: 2,
                        }}
                      >
                        Polish
                      </Box>
                      <div>{group?.group_name_multiple_lang?.pl}</div>
                    </div>
                  )}
                </TableCell>

                <TableCell sx={{ width: 150 }}>
                  <Checkbox
                    checked={group.apply_for_general_report}
                    onChange={(e) =>
                      onChangeCheckBoxValue({
                        groupId: group.id,
                        applyType: 'apply_for_general_report',
                        reasonId: null,
                        status: e.target.checked,
                        index,
                        reasonIndex: null,
                      })
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <Checkbox
                    checked={group.apply_for_report_an_user}
                    onChange={(e) =>
                      onChangeCheckBoxValue({
                        groupId: group.id,
                        applyType: 'apply_for_report_an_user',
                        reasonId: null,
                        status: e.target.checked,
                        index,
                        reasonIndex: null,
                      })
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <Checkbox
                    checked={group.apply_for_report_an_content}
                    onChange={(e) =>
                      onChangeCheckBoxValue({
                        groupId: group.id,
                        applyType: 'apply_for_report_an_content',
                        reasonId: null,
                        status: e.target.checked,
                        index,
                        reasonIndex: null,
                      })
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: 150 }}>
                  <ActionCell
                    menuItems={[RENAME_GROUP, DELETE_MENU]}
                    onClickOption={onClickOption}
                    isOpenCollaspe={openRows[group.id]}
                    onToggle={handleToggle}
                    id={group.id}
                    groupId={group.id}
                    reasonId={null}
                    index={index}
                    reasonIndex={null}
                    isShowCollaspe
                  />
                </TableCell>
              </TableRow>

              {/* Collapsible row for reasons */}
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                  <Collapse
                    in={openRows[group.id]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Table size="small">
                      <TableBody
                        sx={{
                          '& .MuiTableRow-root:last-child': {
                            '& .MuiTableCell-root': { borderBottom: 'none' },
                          },
                        }}
                      >
                        {group.reasons &&
                          group.reasons.length > 0 &&
                          group.reasons.map((reason, reasonIndex) => (
                            <TableRow
                              key={reason.id}
                              sx={{
                                '& .Mui-checked': {
                                  color: '#8EF351 !important',
                                },
                              }}
                            >
                              <TableCell sx={{ border: 'none', width: 100 }} />
                              <TableCell
                                sx={{
                                  width: 'calc(100% - 625px)',
                                }}
                              >
                                {reason.isEditting ? (
                                  <div>
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                      }}
                                    >
                                      English
                                    </Box>
                                    <TextField
                                      id="standard-basic"
                                      value={reason?.reason}
                                      variant="standard"
                                      onChange={(event) => {
                                        onChangeInputValue({
                                          index,
                                          reasonIndex,
                                          value: event.target.value,
                                          lang: 'en',
                                        })
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                        marginTop: 2,
                                      }}
                                    >
                                      Lithuanian
                                    </Box>
                                    <TextField
                                      id="standard-basic"
                                      value={reason?.reason_multiple_lang?.lt}
                                      variant="standard"
                                      onChange={(event) => {
                                        onChangeInputValue({
                                          index,
                                          reasonIndex,
                                          value: event.target.value,
                                          lang: 'lt',
                                        })
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                        marginTop: 2,
                                      }}
                                    >
                                      Polish
                                    </Box>
                                    <TextField
                                      id="standard-basic"
                                      value={reason?.reason_multiple_lang?.pl}
                                      variant="standard"
                                      onChange={(event) => {
                                        onChangeInputValue({
                                          index,
                                          reasonIndex,
                                          value: event.target.value,
                                          lang: 'pl',
                                        })
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                      }}
                                    >
                                      English
                                    </Box>
                                    <div>
                                      {reason.reason ??
                                        reason?.reason_multiple_lang?.en}
                                    </div>
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                        marginTop: 2,
                                      }}
                                    >
                                      Lithuanian
                                    </Box>
                                    <div>
                                      {reason?.reason_multiple_lang?.lt}
                                    </div>
                                    <Box
                                      sx={{
                                        color: '#8F9BB3',
                                        fontSize: 12,
                                        marginTop: 2,
                                      }}
                                    >
                                      Polish
                                    </Box>
                                    <div>
                                      {reason?.reason_multiple_lang?.pl}
                                    </div>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell sx={{ width: 150 }}>
                                <Checkbox
                                  checked={reason.apply_for_general_report}
                                  onChange={(e) =>
                                    onChangeCheckBoxValue({
                                      groupId: group.id,
                                      applyType: 'apply_for_general_report',
                                      reasonId: reason.id,
                                      status: e.target.checked,
                                      index,
                                      reasonIndex,
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ width: 150 }}>
                                <Checkbox
                                  checked={reason.apply_for_report_an_user}
                                  onChange={(e) =>
                                    onChangeCheckBoxValue({
                                      groupId: group.id,
                                      applyType: 'apply_for_report_an_user',
                                      reasonId: reason.id,
                                      status: e.target.checked,
                                      index,
                                      reasonIndex,
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ width: 150 }}>
                                <Checkbox
                                  checked={reason.apply_for_report_an_content}
                                  onChange={(e) =>
                                    onChangeCheckBoxValue({
                                      groupId: group.id,
                                      applyType: 'apply_for_report_an_content',
                                      reasonId: reason.id,
                                      status: e.target.checked,
                                      index,
                                      reasonIndex,
                                    })
                                  }
                                />
                              </TableCell>
                              <TableCell sx={{ width: 150 }}>
                                <ActionCell
                                  menuItems={[RENAME_SUB, DELETE_MENU]}
                                  onClickOption={onClickOption}
                                  isOpenCollaspe={openRows[group.id]}
                                  onToggle={handleToggle}
                                  groupId={group.id}
                                  reasonId={reason.id}
                                  index={index}
                                  reasonIndex={reasonIndex}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell
                            sx={{ border: 'none', textAlign: 'end' }}
                            colSpan={6}
                          >
                            <TextButton
                              label="Add sub"
                              startIcon={<AddIcon />}
                              onClick={() => onAddSub(group.id, index)}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReportSettingsTable
