import { takeLatest, call, put } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'

import { showSnackbar } from 'containers/Snackbar/store/actions'

function* getListReports(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const params = action.payload

  try {
    const { data } = yield call(services.getListReports, params)
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
          index: index + 1 + (params.page - 1) * params.limit,
          ...item,
        }))
        : []

    yield put(
      actions.getListReportsSuccess({
        ...data,
        data: { ...data.data, records },
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getListReportsFailed(data))
  }
}

function* getReportStatistic() {
  try {
    const res = yield call(services.getReportsStatistic)
    yield put(actions.getReportStatisticSuccess(res.data.data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getReportStatisticFail(data))
  }
}
function* getReportSummary() {
  try {
    const res = yield call(services.getReportsSummary)
    yield put(actions.getReportSummarySuccess(res.data.data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getReportSummaryFail(data))
  }
}

function* getDetailReports(action) {
  const { id, navigate, callback } = action.payload
  try {
    const { data } = yield call(services.getDetailReports, id)
    callback && callback(data.data)
    yield put(actions.getDetailReportsSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    navigate('/reports')
    yield put(actions.getDetailReportsFailed(data))
  }
}

function* updateStatusReport(action) {
  const { dataSend, id } = action.payload
  try {
    const { data } = yield call(services.updateStatusReport, id, dataSend)
    yield put(actions.updateStatusReportSuccess(data))
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Update report status success',
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data?.error_message
      })
    )
    yield put(actions.updateStatusReportFailed(data))
  }
}

function* updateActionReport(action) {
  const { dataSend, id } = action.payload
  try {
    const { data } = yield call(services.updateActionReport, id, dataSend)
    yield put(actions.updateActionReportSuccess(data))
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Update report action success',
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data?.error_message
      })
    )
    yield put(actions.updateActionReportFailed(data))
  }
}

export default function* reportsWatcher() {
  yield takeLatest(types.GET_LIST_REPORTS_REQUEST, getListReports)
  yield takeLatest(types.GET_REPORT_STATISTIC_REQUEST, getReportStatistic)
  yield takeLatest(types.GET_REPORT_SUMMARY_REQUEST, getReportSummary)
  yield takeLatest(types.GET_DETAIL_REPORTS_REQUEST, getDetailReports)
  yield takeLatest(types.UPDATE_STATUS_REPORTS_REQUEST, updateStatusReport)
  yield takeLatest(types.UPDATE_ACTION_REPORTS_REQUEST, updateActionReport)
}
