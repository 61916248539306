import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ContainedButton from 'components/Button/ContainedButton'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#00e676', // Green button color
    },
    secondary: {
      main: '#ffffff', // White text for button
    },
    background: {
      paper: '#111422', // Background color for the dialog
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 16,
          borderRadius: 24,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
})

function Popup({
  open,
  onClose,
  onClickSubmit,
  title = '',
  submitText = 'Submit',
  closeText = 'Close',
  note = '',
  bodyComponent,
  icon,
  sx = {},
  btnSubmit = {},
  maxWidthContent = '100%',
}) {
  const { color: btnSubmitColor = '#FF8900', ...restBtnSubmit } = btnSubmit
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={sx}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff',
              background: '#FFFFFF29',
              ':hover': {
                background: '#FFFFFF32',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{ maxWidth: maxWidthContent, width: maxWidthContent }}>
          <DialogContent>
            {icon && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {icon}
              </Box>
            )}
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: 'white',
                fontSize: '30px',
                fontWeight: 700,
                lineHeight: '42px',
                textAlign: 'center',
              }}
            >
              {title}
            </DialogContentText>
            {note && (
              <Box
                sx={{
                  typography: 'noteGrey',
                  textAlign: 'center',
                  fontSize: 14,
                  color: '#8F9BB3',
                  py: 3,
                }}
              >
                {note}
              </Box>
            )}
            {bodyComponent && <>{bodyComponent}</>}
          </DialogContent>
          <DialogActions sx={{ pb: 3 }}>
            {submitText && (
              <Button
                onClick={onClickSubmit}
                variant="outlined"
                type="submit"
                sx={{
                  textTransform: 'none',
                  height: '48px',
                  borderRadius: '16px',
                  fontSize: '16px',
                  color: btnSubmitColor,
                  borderColor: btnSubmitColor,
                  ':hover': {
                    borderColor: btnSubmitColor,
                  },
                  fontWeight: 600,
                }}
                fullWidth
                {...restBtnSubmit}
              >
                {submitText}
              </Button>
            )}
            <ContainedButton
              onClick={onClose}
              variant="contained"
              sx={{ textTransform: 'none', fontWeight: 600 }}
              fullWidth
            >
              {closeText}
            </ContainedButton>
          </DialogActions>
        </Box>
      </Dialog>
    </ThemeProvider>
  )
}

export default Popup
