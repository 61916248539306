import { takeLatest, call, put, select } from 'redux-saga/effects'
import { types } from './constants'
import * as actions from './actions'
import * as services from './services'
import {
  getDatePickerState,
  getEndDatePickerState,
} from 'containers/cache/store/selectors'

import { showSnackbar } from 'containers/Snackbar/store/actions'

function* getListContents(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const { type, ...params } = action.payload
  const startDate = yield select(getDatePickerState())
  const endDate = yield select(getEndDatePickerState())
  try {
    const { data } = yield call(
      type === 'social'
        ? services.getListSocialContents
        : services.getListContents,
      {
        ...params,
        from: startDate,
        to: endDate,
      }
    )
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
            index: index + 1 + (params.page - 1) * params.limit,
            ...item,
          }))
        : []

    yield put(
      actions.getListContentsSuccess({
        ...data,
        data: { ...data.data, records },
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getListContentsFailed(data))
  }
}

function* getContentsOverview() {
  try {
    const startDate = yield select(getDatePickerState())
    const endDate = yield select(getEndDatePickerState())
    const {
      data: { data },
    } = yield call(services.getContentsOverview, {
      from: startDate,
      to: endDate,
    })
    yield put(actions.getContentsOverviewSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getContentsOverviewFailed(data))
  }
}

function* getDetailContent(action) {
  const { id, type, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(
      type === 'social'
        ? services.getDetailSocialContent
        : services.getDetailContent,
      id
    )
    callback && callback(data)
    yield put(actions.getDetailContentSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getDetailContentFailed(data))
  }
}

function* getDetailContentReports(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const { params, type } = action.payload

  try {
    const { data } = yield call(services.getReports, params, type)
    const records =
      data?.data?.records?.length > 0
        ? data.data.records.map((item, index) => ({
            index: index + 1 + (params.page - 1) * params.limit,
            ...item,
          }))
        : []

    yield put(
      actions.getDetailContentReportsSuccess({
        ...data,
        data: { ...data.data, records },
      })
    )
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getDetailContentReportsFailed(data))
  }
}

function* getDetailContentAttendEvent(action) {
  // {sort_by, limit, page, sort_direction,cursor,search,account_type,report_health}
  const { id, params } = action.payload
  try {
    const { data } = yield call(services.getPlanningToAttend, id, params)
    yield put(actions.getDetailContentAttendEventSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getDetailContentAttendEventFailed(data))
  }
}

function* getContentReactions(action) {
  const { id, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getContentReactions, id)
    callback && callback(data)
    yield put(actions.getContentReactionsSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getContentReactionsFailed(data))
  }
}

function* getContentComments(action) {
  const { id, callback } = action.payload
  try {
    const {
      data: { data },
    } = yield call(services.getContentComments, id)
    callback && callback(data)
    yield put(actions.getContentCommentsSuccess(data))
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
    yield put(actions.getContentCommentsFailed(data))
  }
}

function* deleteComment(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deleteComment, id)
    callback && callback()
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
  }
}

function* deleteContent(action) {
  const { id, callback } = action.payload
  try {
    yield call(services.deleteContent, id)
    callback && callback()
  } catch (error) {
    const { data } = error
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: data.error_message,
      })
    )
  }
}

export default function* contentsWatcher() {
  yield takeLatest(types.GET_LIST_CONTENTS_REQUEST, getListContents)
  yield takeLatest(types.GET_CONTENTS_OVERVIEW_REQUEST, getContentsOverview)
  yield takeLatest(types.GET_DETAIL_CONTENT_REQUEST, getDetailContent)
  yield takeLatest(
    types.GET_DETAIL_CONTENT_REPORTS_REQUEST,
    getDetailContentReports
  )
  yield takeLatest(
    types.GET_DETAIL_CONTENT_ATTEND_EVENT_REQUEST,
    getDetailContentAttendEvent
  )
  yield takeLatest(types.GET_CONTENT_REACTIONS_REQUEST, getContentReactions)
  yield takeLatest(types.GET_CONTENT_COMMENTS_REQUEST, getContentComments)
  yield takeLatest(types.DELETE_CONTENT_COMMENT, deleteComment)
  yield takeLatest(types.DELETE_CONTENT, deleteContent)
}
