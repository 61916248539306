import TextField from '@mui/material/TextField'
import { styled } from '@mui/system'

const TextInput = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '16px', // Custom border radius
    fontSize: 16,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#2E3A59',
  },
})

export default TextInput
