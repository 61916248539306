import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'

const OutlinedButton = styled(MuiButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: 8,
  color: theme.palette.primary.secondary,
  borderColor: theme.palette.primary.secondary,
  '&:hover': {
    borderColor: theme.palette.primary.secondary,
  },
  fontSize: 14,
}))

export default function OutlinedGreenButton({ onClick, label = '', ...rest }) {
  return (
    <OutlinedButton
      variant="outlined"
      sx={{ mr: 2 }}
      onClick={onClick}
      {...rest}
    >
      {label}
    </OutlinedButton>
  )
}
