import { createSelector } from 'reselect'
import { get } from 'lodash'

const settingsState = (state) => get(state, 'settings')

const getSettingsState = () =>
  createSelector(settingsState, (settings) => settings)

const getReportsSettingSelector = () =>
  createSelector(settingsState, (settings) => settings.reports.report)

const getAutoLockSettingSelector = () =>
  createSelector(settingsState, (settings) => settings.reports.autoLock)

const getSubpageSettingSelector = () =>
  createSelector(settingsState, (settings) => settings.reports.subpage)

export {
  getSettingsState,
  getReportsSettingSelector,
  getAutoLockSettingSelector,
  getSubpageSettingSelector
}
