import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import App from './App'
import { theme } from './theme'
import configureStore from './store/configureStore'
import { loadState, saveState } from 'utils/localStorage'
import { throttle } from 'lodash'

;(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  const persistedState = loadState()
  const store = configureStore({ cache: persistedState })
  store.subscribe(
    throttle(() => {
      saveState(store.getState())
    }, 1000)
  )
  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  )
})()
