import React, { useCallback, useEffect, useState } from 'react'
import { Box, CircularProgress, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FilledButton from 'components/Button/FilledButton'
import ReportSettingsTable from './ReportSettingsTable'
import OutlinedGreenButton from 'components/Button/OutlinedGreenButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  getReportsSettingRequest,
  saveReportsSettingRequest,
} from './store/actions'
import { getReportsSettingSelector } from './store/selectors'

const ReportsSetting = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const { data, isLoading } = useSelector(getReportsSettingSelector())

  const [reports, setReports] = useState([])
  const [removeGroupIds, setRemoveGroupIds] = useState([])
  const [removeReasonIds, setRemoveReasonIds] = useState([])

  const handleChangeCheckBoxValue = ({
    groupId,
    applyType,
    reasonId,
    status,
    index,
    reasonIndex,
  }) => {
    const nextListReports = [...reports]
    if (reasonIndex !== null) {
      const nextReport = { ...reports[index] }
      const nextReasonReport = [...nextReport.reasons]
      nextReasonReport[reasonIndex] = {
        ...nextReasonReport[reasonIndex],
        [applyType]: status,
      }

      nextListReports[index] = {
        ...nextReport,
        reasons: nextReasonReport,
      }
      setReports(nextListReports)
      return
    }
    nextListReports[index] = {
      ...nextListReports[index],
      [applyType]: status,
    }
    setReports(nextListReports)
    return
  }

  const handleAddSub = (groupId, groupIndex) => {
    const nextListReports = [...reports]
    const nextReport = { ...reports[groupIndex] }
    const nextReasonReport = [
      ...(nextReport?.reasons || []),
      {
        apply_for_general_report: false,
        apply_for_report_an_user: false,
        apply_for_report_an_content: false,
        isEditting: true,
      },
    ]

    nextListReports[groupIndex] = {
      ...nextReport,
      reasons: nextReasonReport,
    }
    setReports(nextListReports)
  }

  const handleClickOption = ({
    index,
    optionId,
    reasonIndex,
    groupId,
    reasonId,
  }) => {
    let nextListReports = [...reports]
    if (optionId.includes('rename')) {
      if (optionId === 'rename-sub') {
        const nextReport = { ...reports[index] }
        const nextReasonReport = [...nextReport.reasons]
        nextReasonReport[reasonIndex] = {
          ...nextReasonReport[reasonIndex],
          isEditting: true,
        }

        nextListReports[index] = {
          ...nextReport,
          reasons: nextReasonReport,
        }
        setReports(nextListReports)
        return
      }
      nextListReports[index] = {
        ...nextListReports[index],
        isEditting: true,
      }
      setReports(nextListReports)
      return
    }

    if (reasonIndex !== null) {
      const nextReport = { ...reports[index] }
      const nextReasonReport = nextReport.reasons.filter(
        (_, index) => index !== reasonIndex
      )
      nextListReports[index] = {
        ...nextReport,
        reasons: nextReasonReport,
      }
      setReports(nextListReports)
      if (reasonId) {
        setRemoveReasonIds((prev) => [...prev, reasonId])
      }
      return
    }
    nextListReports = reports.filter((_, groupIndex) => groupIndex !== index)
    setReports(nextListReports)
    if (groupId) {
      setRemoveGroupIds((prev) => [...prev, groupId])
    }
    return
  }

  const handleAddGroup = () => {
    const idReportTable = document.getElementById('table-report')
    idReportTable.scrollTop = idReportTable.scrollHeight
    const nextListReports = [
      ...reports,
      {
        reason: 'New sub',
        apply_for_general_report: false,
        apply_for_report_an_user: false,
        apply_for_report_an_content: false,
        isEditting: true,
      },
    ]
    setReports(nextListReports)
    return
  }

  const handleChangeInputValue = ({ index, reasonIndex, value, lang }) => {
    const nextListReports = [...reports]
    if (reasonIndex !== null) {
      const nextReport = { ...reports[index] }
      const nextReasonReport = [...nextReport.reasons]
      nextReasonReport[reasonIndex] =
        lang === 'en'
          ? {
              ...nextReasonReport[reasonIndex],
              reason: value,
            }
          : {
              ...nextReasonReport[reasonIndex],
              reason_multiple_lang: {
                ...nextReasonReport[reasonIndex].reason_multiple_lang,
                [lang]: value,
              },
            }

      nextListReports[index] = {
        ...nextReport,
        reasons: nextReasonReport,
      }
      setReports(nextListReports)
      return
    }
    nextListReports[index] =
      lang === 'en'
        ? {
            ...nextListReports[index],
            group_name: value,
          }
        : {
            ...nextListReports[index],
            group_name_multiple_lang: {
              ...nextListReports[index].group_name_multiple_lang,
              [lang]: value,
            },
          }
    setReports(nextListReports)
    return
  }

  const handleResponseReport = (data) => {
    setReports(data)
  }

  const fetchReportSetting = useCallback(() => {
    dispatch(getReportsSettingRequest(handleResponseReport))
  }, [dispatch])

  const handleSaveChange = () => {
    const dataSubmit = {
      groups: reports,
    }
    if (removeGroupIds.length > 0) {
      dataSubmit.remove_group_ids = removeGroupIds
    }
    if (removeReasonIds.length > 0) {
      dataSubmit.remove_reason_ids = removeReasonIds
    }
    dispatch(saveReportsSettingRequest(dataSubmit, fetchReportSetting))
  }

  useEffect(() => {
    fetchReportSetting()
  }, [fetchReportSetting])

  return (
    <Box sx={{ width: '100%', height: 'calc(100vh - 147px)' }}>
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          borderRadius: '8px',
          p: 3,
          height: '100%',
        }}
      >
        <Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ typography: 'title' }}>Report reasons</Box>
              <FilledButton
                isEnable={JSON.stringify(data) !== JSON.stringify(reports)}
                label={'Save changes'}
                onClick={handleSaveChange}
                disabled={JSON.stringify(data) === JSON.stringify(reports)}
              />
            </Box>
            <Box sx={{ typography: 'noteGrey' }}>
              Setup which reasons that users can choose to report.
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: isLoading ? 'flex' : 'none',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '400px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <ReportSettingsTable
                data={reports}
                onChangeCheckBoxValue={handleChangeCheckBoxValue}
                onAddSub={handleAddSub}
                onClickOption={handleClickOption}
                onChangeInputValue={handleChangeInputValue}
              />
            )}
          </Box>
          <Box sx={{ mt: 3, textAlign: 'end' }}>
            <OutlinedGreenButton
              label="Add new group"
              startIcon={<AddIcon />}
              onClick={handleAddGroup}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ReportsSetting
