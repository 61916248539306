export const types = {
  TURN_OFF_NOTIFICATION: 'settings/TURN_OFF_NOTIFICATION',
  TURN_OFF_NOTIFICATION_SUCCESS: 'settings/TURN_OFF_NOTIFICATION_SUCCESS',
  TURN_OFF_NOTIFICATION_FAILED: 'settings/TURN_OFF_NOTIFICATION_FAILED',

  FETCH_MAINTENANCE_MODE: 'settings/FETCH_MAINTENANCE_MODE',
  FETCH_MAINTENANCE_MODE_SUCCESS: 'settings/FETCH_MAINTENANCE_MODE_SUCCESS',

  GET_REPORTS_SETTING: 'settings/GET_REPORTS_SETTING',
  GET_REPORTS_SETTING_SUCCESS: 'settings/GET_REPORTS_SETTING_SUCCESS',
  GET_REPORTS_SETTING_FAILED: 'settings/GET_REPORTS_SETTING_FAILED',

  SAVE_REPORTS_SETTING: 'settings/SAVE_REPORTS_SETTING',
  SAVE_REPORTS_SETTING_SUCCESS: 'settings/SAVE_REPORTS_SETTING_SUCCESS',
  SAVE_REPORTS_SETTING_FAILED: 'settings/SAVE_REPORTS_SETTING_FAILED',

  GET_AUTO_LOCK_SETTING: 'settings/GET_AUTO_LOCK_SETTING',
  GET_AUTO_LOCK_SETTING_SUCCESS: 'settings/GET_AUTO_LOCK_SETTING_SUCCESS',
  GET_AUTO_LOCK_SETTING_FAILED: 'settings/GET_AUTO_LOCK_SETTING_FAILED',

  SAVE_AUTO_LOCK_SETTING: 'settings/SAVE_AUTO_LOCK_SETTING',
  SAVE_AUTO_LOCK_SETTING_SUCCESS: 'settings/SAVE_AUTO_LOCK_SETTING_SUCCESS',
  SAVE_AUTO_LOCK_SETTING_FAILED: 'settings/SAVE_AUTO_LOCK_SETTING_FAILED',

  GET_SUB_PAGE_SETTING: 'settings/GET_SUB_PAGE_SETTING',
  GET_SUB_PAGE_SETTING_SUCCESS: 'settings/GET_SUB_PAGE_SETTING_SUCCESS',
  GET_SUB_PAGE_SETTING_FAILED: 'settings/GET_SUB_PAGE_SETTING_FAILED',

  SAVE_SUB_PAGE_SETTING: 'settings/SAVE_SUB_PAGE_SETTING',
  SAVE_SUB_PAGE_SETTING_SUCCESS: 'settings/SAVE_SUB_PAGE_SETTING_SUCCESS',
  SAVE_SUB_PAGE_SETTING_FAILED: 'settings/SAVE_SUB_PAGE_SETTING_FAILED',
}
