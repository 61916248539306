import React, { useState } from 'react'


import { TABS_REPORTS } from 'constants/settings/consts'
import TableTab from 'components/Tabs/TableTab'
import ReportsSetting from './ReportSettings'
import { Grid } from '@mui/material'
import AutoLock from './AutoLock'

const ReportsSettingContainer = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue)
  }


  return (
    <Grid sx={{ p: 3, width: '100%' }}>
      <TableTab
        tabs={TABS_REPORTS}
        currentTab={selectedTab}
        onChange={handleChangeTab}
      />
      {selectedTab === 0 && <ReportsSetting />}
      {selectedTab === 1 && <AutoLock />}
    </Grid>
  )
}

export default ReportsSettingContainer
